import React from 'react';
import { MapPin } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerLocations: IRouter = {
  path: '/locations',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'locations.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <MapPin />,
  },
  permissionCode: PermissionEnum.LocationsView,
};

export const routerLocationsPageInfo: IRouter = {
  path: '/locations/page-info/:id',
  loader: React.lazy(() => import('./component/PageInfo')),
  exact: true,
  name: 'locations.pageInfo.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.LocationsViewDetail,
};
