import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerCampaignOperation: IRouter = {
  path: '/campaign-operation',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'campaignOperation.nameTitle', //translate here for breadcrumb and sidebar
  activePath: 'campaign',
  masterLayout: true,
  permissionCode: PermissionEnum.CampaignsView,
};
export const routerFormCampaignOperationEdit: IRouter = {
  path: '/campaigns/edit/:id',
  loader: React.lazy(
    () => import('../CampaignOperation/component/MainModal/FormCampaignOperationEdit'),
  ),
  exact: true,
  name: 'campaigns.edit', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.CampaignsUpdate,
};
export const routerFormCampaignOperationInfo: IRouter = {
  path: '/campaigns/info/:tab/:id',
  loader: React.lazy(
    () => import('../CampaignOperation/component/MainModal/FormCampaignOperationInfo'),
  ),
  exact: true,
  name: 'campaigns.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.CampaignsViewDetail,
};

export const routerFormCampaignOperationAdd: IRouter = {
  path: '/campaigns/add',
  loader: React.lazy(
    () => import('../CampaignOperation/component/MainModal/FormCampaignOperationEdit'),
  ),
  exact: true,
  name: 'campaigns.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.CampaignsCreate,
};
