export default {
  'giftDetail.name': 'QR code number',
  'giftDetail.placeholder.code': 'Enter information',
  'giftDetail.button.submit': 'Check QR Code',
  'giftDetail.title': 'Enter QR code',

  'giftInfo.title': 'Gift information',
  'giftInfo.info': 'Information QR Code Gift',
  'giftInfo.qrCode': 'QR code gift code:',
  'giftInfo.location': 'Location:',
  'giftInfo.recyclingMachine': 'QR Code Export Recycling Machine:',
  'giftInfo.nameGift': 'Gift name:',
  'giftInfo.winningTime': 'Winning time:',
  'giftInfo.qrCodeExpired': 'Time QR Code is overn:',
  'giftInfo.qrCodeUsageStatus': 'QR code:',
  'giftInfo.rewardTime': 'Reward time:',

  'giftInfo.infoCustomer': 'Customer information',
  'giftInfo.numberPhone': 'Phone number:',
  'giftInfo.nameCustomer': 'Customer name:',
  'button.accept': 'Award',

  'giftInfo.status.param': '{status,select, 1 {Used} 2 {Unused} 3 {Expired} other {Unknown}}',

  'additionalInfo.title': 'Additional information',
  'additionalInfo.info': 'Please enter the customer information!',
  'additionalInfo.nameGift': 'Gift name:',
  'additionalInfo.location': 'Location:',
  'additionalInfo.giftGiver': 'Gifted Gift:',
  'additionalInfo.numberPhone': 'Customer phone number:',
  'additionalInfo.placeholder.numberPhone': 'Please enter the phone number',
  'additionalInfo.nameCustomer': 'Customer name:',
  'additionalInfo.placeholder.nameCustomer': 'Please enter the customer name',
  'additionalInfo.upload': 'Direct images:',
  'additionalInfo.confirm': 'Do you want to confirm this gift to customers?',
  'additionalInfo.agree': 'Successful information!',
  'additionalInfo.validator-name': 'Please enter the customer phone number',

  'Mes.RecallHistory.QrCode.NotFound': 'QR code does not exist',
  'giftDetail.validator-code': 'QR code is not allowed to leave blank',
  'additionalInfo.validator-phone': 'Customer phone number is not in the format',
};