export default {
  //resume

  // tab general infomation
  'campaignGiftManage.totalReach': 'Total reach',
  'campaignGiftManage.totalRetrievedCans': 'Total cans obtained',
  'campaignGiftManage.totalHeinekenCans': 'Total Heineken cans obtained',
  'campaignGiftManage.totalOtherCans': 'Total other obtained cans',
  'campaignGiftManage.can': 'Can',
  'campaignGiftManage.thousand-can': 'Thousand bottles',
  'campaignGiftManage.million-can': 'Million bottles',
  'campaignGiftManage.reach': 'Reaches',
  'campaignGiftManage.thousand-reach': 'Thousand reaches',
  'campaignGiftManage.million-reach': 'Million reaches',

  'campaignGiftManage.createdQRCode': 'Total of created QR code',
  'campaignGiftManage.createdQRCode_unit': 'QR code',
  'campaignGiftManage.numberOfDistributed_unit': 'Total gifts distributed',
  'campaignGiftManage.numberOfExpired_unit': 'Total gifts expired',
  'campaignGiftManage.numberOfCustomer': 'Total customer infomation',
  'campaignGiftManage.numberOfCustomer_unit': 'Customer',

  'campaignGiftManage.campaignGiftList': 'Campaign gift list',

  'campaignGiftManage.giftImage': 'Image',
  'campaignGiftManage.giftName': 'Gift name',
  'campaignGiftManage.giftUnit': 'Gift unit',
  'campaignGiftManage.limit': 'Gift limit',
  'campaignGiftManage.numberOfWon': 'Number of gifts won',
  'campaignGiftManage.numberOfDistributed': 'Number of gifts distributed',
  'campaignGiftManage.numberOfNotYetDistributed': 'Number of gifts undistributed',
  'campaignGiftManage.numberOfExpired': 'Number of expired gifts',
  'campaignGiftManage.inventory': 'Number of gifts left',
  'campaignGiftManage.numberOfAvailable': 'Available gifts',

  'campaignGiftManage.pauseCampaign': 'Pause campaign',
  'campaignGiftManage.continueCampaign': 'Continue campaign',

  //tab history comparison
  'luckyDrawHistory.recallHistories': 'Cans recall and lucky draw history ',
  'luckyDrawHistory.approachTime': 'Approach time',
  'luckyDrawHistory.totalObtained': 'Total cans obtained',
  'luckyDrawHistory.totalValid': 'Total Heineken cans obtained',
  'luckyDrawHistory.totalInValid': 'Total other obtained cans',
  'luckyDrawHistory.totalConversionPoint': 'Total conversion point',
  'luckyDrawHistory.receivedGift': 'Received gift',
  'luckyDrawHistory.winningTime': 'Winning time',
  'luckyDrawHistory.qrCode': 'QR code',
  'luckyDrawHistory.qrCodeUsageTime': 'QR code usage status',
  'luckyDrawHistory.prizeGiver': 'Prize giver',

  'luckyDrawHistory.recyclingMachine': 'Recycling machine',
  'luckyDrawHistory.gift': 'Gift',
  'luckyDrawHistory.QRCodePrintingTime': 'QR code printing time',
  'luckyDrawHistory.qrCodeUsageStatus': 'QR code usage status',
  'luckyDrawHistory.qrCodeUsageStatus1': 'Used',
  'luckyDrawHistory.qrCodeUsageStatus2': 'Unused',
  'luckyDrawHistory.qrCodeUsageStatus3': 'Expired',
  'luckyDrawHistory.notSpinYet': 'Not spinned',
  'luckyDrawHistory.spinFail': 'No prize',

  'giftDistributionHistory.PGGivingGiftHistory': 'Gift distribution by PG history',
  'giftDistributionHistory.approachTime': 'Approach time',
  'giftDistributionHistory.totalObtained': 'Total cans obtained',
  'giftDistributionHistory.totalValid': 'Total Heineken cans obtained',
  'giftDistributionHistory.totalInValid': 'Total other obtained cans',
  'giftDistributionHistory.totalConversionPoint': 'Total conversion point',
  'giftDistributionHistory.receivedGift': 'Received gift',
  'giftDistributionHistory.winningTime': 'Winning time',
  'giftDistributionHistory.qrCode': 'QR code',
  'giftDistributionHistory.qrCodeUsageTime': 'QR code usage status',
  'giftDistributionHistory.prizeGiver': 'Prize giver',
  'giftDistributionHistory.customerPhone': 'Customer phone',
  'giftDistributionHistory.customerName': 'Customer name',
  'giftDistributionHistory.customerImage': 'Image',

  'giftDistributionHistory.givingGiftTime': 'Gift giving time',
  'giftDistributionHistory.recyclingMachine': 'Recycling machine',
  'giftDistributionHistory.gift': 'Gift',

  //modal update gift quantity
  'campaignGiftManage.updateGiftQuantityTitle': 'Gift quantity update history',

  //modal gift quantity update history
  'campaignGiftManage.updateHistoryTitle': 'Gift quantity update history',
  'campaignGiftManage.updateTime': 'Update time',

  'campaignGiftManage.quantityBeforeUpdate': 'Quantity before update',
  'campaignGiftManage.quantityAfterUpdate': 'Quantity after update',
  'campaignGiftManage.inventoryAfterUpdate': 'Inventory after update',
  'campaignGiftManage.updateAuthor': 'Update author',

  //modal recall history
  'campaignGiftManage.recallInfo': 'Prize winning information',
  'campaignGiftManage.location': 'Location',
  'campaignGiftManage.recallHistory': 'Prize winning history',

  //shared

  'campaignGiftManage.action': 'Action',
  'campaignGiftManage.confirm': 'Confirm',
  'campaignGiftManage.cancel': 'Cancel',
  'campaignGiftManage.agree': 'Agree',
  'campaignGiftManage.confirmPauseCamp': 'Can you confirm pause this campaign?',
  'campaignGiftManage.confirmContinueCamp': 'Can you confirm continue this campaign',
  'campaignGiftManage.confirmUpdateGiftQuantity': 'Can you confirm update this gift quantity?',

  'campaignGiftManage.selectMachine': 'Select recycling machine',
  'campaignGiftManage.selectGift': 'Select gift',
  'campaignGiftManage.selectStatus': 'Select usage status',

  'campaignGiftManage.qrCodeUsageStatus.id':
    '{status, select, 1 {Used} 2 {Unused} 3 {Expired} other {Undefined}}',
  'campaignGiftManage.recyclingMachine': 'Recycling machine',
  'campaignGiftManage.startDay': 'Start',
  'campaignGiftManage.endDay': 'End',
  'campaignGiftManage.campaign': 'Campaign',

  //trash
  'campaignGiftManage.recallHistories': 'Cans recall and lucky draw history ',
  'campaignGiftManage.approachTime': 'Approach time',
  'campaignGiftManage.totalObtained': 'Total cans obtained',
  'campaignGiftManage.totalValid': 'Total Heineken cans obtained',
  'campaignGiftManage.totalInValid': 'Total other obtained cans',
  'campaignGiftManage.totalConversionPoint': 'Total conversion point',
  'campaignGiftManage.receivedGift': 'Received gift',
  'campaignGiftManage.winningTime': 'Winning time',
  'campaignGiftManage.qrCode': 'QR code',
  'campaignGiftManage.qrCodeUsageTime': 'QR code usage status',
  'campaignGiftManage.prizeGiver': 'Prize giver',
  'campaignGiftManage.gift': 'Gift',
  'campaignGiftManage.QRCodePrintingTime': 'QR code printing time',
  'campaignGiftManage.qrCodeUsageStatus': 'QR code usage status',
  'campaignGiftManage.qrCodeUsageStatus1': 'Used',
  'campaignGiftManage.qrCodeUsageStatus2': 'Unused',
  'campaignGiftManage.qrCodeUsageStatus3': 'Expired',
  'campaignGiftManage.notSpinYet': 'Not spinned',
  'campaignGiftManage.spinFail': 'No prize',
  'campaignGiftManage.PGGivingGiftHistory': 'Gift distribution by PG history',
  'campaignGiftManage.customerPhone': 'Customer phone',
  'campaignGiftManage.customerName': 'Customer name',
  'campaignGiftManage.customerImage': 'Image',
  'campaignGiftManage.givingGiftTime': 'Gift giving time',
};
