import { routerForgotPassword } from '@view/Auth/ForgotPassword/router';
import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerResetPassword } from '@view/Auth/ResetPassword/router';
import {
  routerCampaignOperation,
  routerFormCampaignOperationAdd,
  routerFormCampaignOperationEdit,
  routerFormCampaignOperationInfo
} from '@view/Campaigns/pages/CampaignOperation/router';
import {
  routerFormRulesAdd,
  routerFormRulesEdit
} from '@view/Campaigns/pages/Rules/FormRules/router';
import { routerRules } from '@view/Campaigns/pages/Rules/router';
import { routerCampaigns } from '@view/Campaigns/router';
import { routerCustomerInfo } from '@view/Customer/page/CustomerInfo/router';
import { routerCustomer } from '@view/Customer/router';
import { routerFormGiftInfo, routerGift } from '@view/Gift/router';
import { routerHome } from '@view/Home/router';
import { routerLocations, routerLocationsPageInfo } from '@view/Locations/router';
import { routerPageError } from '@view/PageError/router';
import { routerRecycleDetailPage } from '@view/Recycle/component/DetailPage/router';
import { routerRecycle } from '@view/Recycle/router';
import { routerDeliveryRobot } from '@view/Report/pages/DeliveryRobot/router';
import { routerGiftStatistics } from '@view/Report/pages/GiftStatistics/router';
import { routerRecyclingMachine } from '@view/Report/pages/RecyclingMachine/router';
import { routerRobotSilverbot } from '@view/Report/pages/RobotSilverbot/router';
import { routerReport } from '@view/Report/router';
import { routerRobot } from '@view/Robot/router';
import { routerAreas } from '@view/Settings/pages/Areas/router';
import { routerRoles } from '@view/Settings/pages/Roles/router';
import { routerUserInfo, routerUsers } from '@view/Settings/pages/Users/router';
import { routerSetting } from '@view/Settings/router';
import { routerBeginScreenCampaignsDetail } from '@view/WebApp/Pages/BeginScreen/CampaignsDetail/router';
import { routerBeginScreen, routerWHomeWebApp } from '@view/WebApp/Pages/BeginScreen/router';
import {
  routerFormGiftAdditionalInfo,
  routerFormGiftDetailInfo,
  routerGiftDetailScreen
} from '@view/WebApp/Pages/GiftDetailScreen/router';
import { routerInfoGiftHistory } from '@view/WebApp/Pages/HistoryScreen/InfoGiftHistory/router';
import { routerGiftHistory } from '@view/WebApp/Pages/HistoryScreen/router';
import { routerViewProfileApp } from '@view/WebApp/Pages/Profiles/router';

import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerHome,
  routerCampaigns,
  routerFormCampaignOperationInfo,
  routerFormCampaignOperationEdit,
  routerFormCampaignOperationAdd,
  routerCampaignOperation,
  routerRobot,
  routerRules,
  routerFormRulesAdd,
  routerFormRulesEdit,
  routerViewProfile,
  routerRecycle,
  routerRecycleDetailPage,
  routerLocations,
  routerCustomerInfo,
  routerUsers,
  routerUserInfo,
  routerRoles,
  routerLocationsPageInfo,
  routerFormGiftInfo,
  routerAreas,
  routerGift,
  routerCustomer,
  routerReport,
  routerRobotSilverbot,
  routerDeliveryRobot,
  routerGiftStatistics,
  routerRecyclingMachine,
  routerSetting,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerLogin,
  routerForgotPassword,
  routerResetPassword,
  routerPageError,
];

export const webAppPage: IRouter[] = [
  routerWHomeWebApp,
  routerBeginScreen,
  routerGiftDetailScreen,
  routerGiftHistory,
  routerFormGiftDetailInfo,
  routerFormGiftAdditionalInfo,
  routerInfoGiftHistory,
  routerBeginScreenCampaignsDetail,
  routerViewProfileApp,
  routerPageError,
];
