import { RootState } from '@modules';
import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';

interface ICampaignsStore {
  campaignId?: string;
}

const campaignsStore = createSlice({
  name: 'campaignsStore',
  initialState: {
    campaignId: undefined,
  } as ICampaignsStore,

  reducers: {
    setCampaignId: (state, action: PayloadAction<any>) => {
      state.campaignId = action.payload;
    },
  },
});

export const campaignsSelector: Selector<RootState, any> = (state: any) => state.campaignsStore.campaignId;

export default campaignsStore;
