import React from 'react';
import { Clipboard } from 'react-feather';

import { IRouter } from '@routers/interface';

import { routerDeliveryRobot } from './pages/DeliveryRobot/router';
import { routerGiftStatistics } from './pages/GiftStatistics/router';
import { routerRecyclingMachine } from './pages/RecyclingMachine/router';
import { routerRobotSilverbot } from './pages/RobotSilverbot/router';

export const routerReport: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'report.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Clipboard />,
    activePath: 'report',
  },
  routes: [routerRobotSilverbot, routerDeliveryRobot, routerRecyclingMachine, routerGiftStatistics],
};