import React from 'react';

import { IRouter } from '@routers/interface';

export const routerRecyclingMachine: IRouter = {
  path: '/recycling-machine',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'recyclingMachine.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};