import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerRecycleDetailPage: IRouter = {
  path: '/recycle/detail-page/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'recycle.detail-page', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.RecyclingMachinesViewDetail],
};
