export default {
  'campaigns.name': 'Chiến dịch',
  'campaignOperation.name': 'Tên chiến dịch',
  'campaignOperation.nameTitle': 'Vận hành chiến dịch',
  'campaignOperation.recyclingMachines': 'Tên máy tái chế',
  'campaignOperation.location': 'Địa điểm',
  'campaignOperation.takePlaceFrom': 'Ngày bắt đầu',
  'campaignOperation.takePlaceTo': 'Ngày kết thúc',
  'campaignOperation.rule': 'Quy tắc trúng thưởng',
  'campaignOperation.numberOfHits': 'Số lượt tiếp cận',
  'campaignOperation.validAmount': 'Số lon Heineken',
  'campaignOperation.invalidAmount': 'Số lon khác',
  'campaignOperation.action': 'Hành động',
  'campaigns.edit': 'Chỉnh sửa chiến dịch',
  'campaignOperation.numberOfDistributed': 'Số quà tặng đã phát',

  'campaignOperation.time': 'Thời gian diễn ra',
  'campaignOperation.locations': 'Địa điểm thực hiện',
  'campaignOperation.machine': 'Máy tái chế',
  'campaignOperation.ruleGift': 'Quy tắc trúng thưởng',
  'campaignOperation.placeholder': 'Vui lòng chọn máy tái chế',
  'campaignOperation.placeholder.name': 'Vui lòng nhập tên chiến dịch',
  'campaignOperation.info.name': 'Tên chiến dịch',
  'campaignOperation.typeGift': 'Vui lòng nhập số lượng quà tặng ban đầu',
  'campaignOperation.numberOfWon': 'Số QR code đã trúng',
  'campaignOperation.content.delete': 'Bạn có muốn xóa chiến dịch này?',
  'campaignOperation.placeholder.locations': 'Vui lòng chọn địa điểm thực hiện',

  'campaign-operation.create': 'Thêm chiến dịch mới',
  'campaigns.add': 'Tạo chiến dịch mới',
  'campaigns.info': 'Thông tin chiến dịch',
  'campaignOperation.placeholder.ruleGift': 'Vui lòng chọn quy tắc trúng thưởng',
  'campaignGifts.typeGift': 'Loại quà tặng',
  'campaignGifts.quantityGift': 'Số lượng quà tặng',
  'campaignGifts.title': 'Vui lòng nhập số lượng quà tặng ban đầu!',
  'campaignGifts.placeholder.limit': 'Vui lòng nhập số lượng',
  'campaignGifts.limit': 'Vui lòng nhập số lượng quà tặng',

  'gift.info.name': 'Tên chiến dịch:',
  'gift.info.location': 'Địa điểm:',
  'gift.info.machine': 'Máy tái chế:',
  'gift.info.rule': 'Quy tắc trúng thưởng:',
  'gift.info.startDay': 'Từ ngày:',
  'gift.info.endDay': 'Đến ngày:',
  'gift.info.status': 'Trạng thái chiến dịch:',

  'campaignOperation.status': 'Trạng thái chiến dịch',
  'campaign.status.param':
    '{status,select, 1 {Chưa bắt đầu} 2 {Đang diễn ra} 3 {Đã tạm ngưng} 4 {Đã kết thúc} other {Không xác định}}',

  'campaignGifts.generalInfo': 'Thông tin chung',
  'campaignGifts.history': 'Lịch sử đối soát',

  'campaignOperation.filter.status': 'Trạng thái',
  'campaignOperation.filter.status.notStarted': 'Chưa bắt đầu',
  'campaignOperation.filter.status.happenning': 'Đang diễn ra',
  'campaignOperation.filter.status.paused': 'Đã tạm ngưng',
  'campaignOperation.filter.status.finished': 'Đã kết thúc',
  'campaignOperation.required': 'Vui lòng nhập ngày kết thúc',
};
