import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerAreas: IRouter = {
  path: '/areas',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'areas.maintitle', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.AreasView,
};
