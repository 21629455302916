import React from 'react';
import { Database } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerRecycle: IRouter = {
  path: '/recycle',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'recycle.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Database />,
  },
  permissionCode: [PermissionEnum.RecyclingMachinesView],
};
