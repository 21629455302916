import React from 'react';
import { Bookmark } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

import { routerCampaignOperation } from './pages/CampaignOperation/router';
import { routerRules } from './pages/Rules/router';

export const routerCampaigns: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'campaigns.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    activePath: 'campaigns',
    icon: <Bookmark />,
  },
  routes: [routerCampaignOperation, routerRules],
  permissionCode: [PermissionEnum.CampaignsView, PermissionEnum.RulesView],
};
