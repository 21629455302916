export default {
  'rules.title': 'Quy tắc trúng thưởng',
  'rules.name': 'Tên quy tắc',
  'rules.description': 'Mô tả',
  'rules.numberOfCampaignsToApply': 'Số địa điểm áp dụng',
  'rules.createdAt': 'Ngày tạo',
  'rules.action': 'Hành động',
  'rules.content.delete': 'Bạn có xác nhận xóa quy tắc này?',
  'rules.create': 'Thêm quy tắc trúng thưởng mới',
  'rules.scoreFrom': 'Từ',
  'rules.scoreTo': 'Đến',
  'rules.rangePoint': 'Phạm vi tích lũy điểm:',
  'rules.from': 'Từ',
  'rules.deco': 'Đến',
  'rules.to': 'Đến',
  'rules.nameGift': 'Quà tặng',
  'rules.not-win': 'Không trúng thưởng',
  'rules.addGift': 'Thêm quà tặng',
  'rules.addRule': 'Thêm phạm vi tích lũy mới',
  'rules.placeholder.name': 'Nhập tên quy tắc',
  'rules.placeholder.description': 'Nhập mô tả',
  'rules.rate': 'Tỉ lệ',
  'rules.info': 'Thông tin quy tắc',
  'rules.add': 'Thêm quy tắc',
  'rules.edit': 'Chỉnh sửa quy tắc',
  'rules.btn-edit': 'Cập nhật',
  'rules.btn-add': 'Thêm',
  'rules.setRule': 'Thiết lập quy tắc',
  'rules.required-score': 'Nhập số điểm',
  'rules.content-gift': 'Nội dung quà tặng',
  'rules.validator-name': 'Tên quy tắc không được để trống',
  'rules.validator-gift': 'Vui lòng chọn quà tặng',

  'rules.sub-range': 'P/vi phụ',
  'rules.placeholder.sub-range': 'Vui lòng chọn phạm vi phụ',
};
