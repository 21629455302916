import React from 'react';
import { IRouter } from '@routers/interface';

export const routerInfoGiftHistory: IRouter = {
  path: '/info-gift-history/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'infoGiftHistory.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
