import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';

import { routerRoles } from './pages/Roles/router';
import { routerUsers } from './pages/Users/router';
import { routerAreas } from './pages/Areas/router';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerSetting: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  name: 'setting.name', //translate here for breadcrumb and sidebar
  menu: {
    activePath: 'setting',
    icon: <Icon.Settings />,
  },
  routes: [routerAreas, routerUsers, routerRoles],
  permissionCode: [PermissionEnum.AreasView, PermissionEnum.UsersView, PermissionEnum.RolesView],
};
