import React from 'react';

import { IRouter } from '@routers/interface';

export const routerDeliveryRobot: IRouter = {
  path: '/delivery-robot',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'deliveryRobot.name', //translate here for breadcrumb and sidebar
  masterLayout: true,

};