import { useIntl } from 'react-intl';

export const useAltaIntl = () => {
  const intl = useIntl();
  const formatMessage = (key: string): string => {
    return intl.formatMessage({ id: key });
  };
  const formatCurrency = (v: number) => {
    return intl.formatNumber(v, {
      style: 'currency',
      currency: 'vnd',
      unitDisplay: 'narrow',
      notation: 'standard',
    });
  };
  const convertUnit = number => {
    if (number >= 1000000) {
      return formatMessage('recycle.million-can');
    } else if (number >= 1000) {
      return formatMessage('recycle.thousand-can');
    } else {
      return formatMessage('recycle.can');
    }
  };
  const convertUnitDevice = number => {
    if (number >= 1000000) {
      return formatMessage('recycle.million-device');
    } else if (number >= 1000) {
      return formatMessage('recycle.thousand-device');
    } else {
      return formatMessage('recycle.device');
    }
  };
  return {
    intl,
    formatMessage,
    formatCurrency,
    convertUnit,
    convertUnitDevice,
  };
};
