export default {
  'giftDetail.name': 'Mã số QR code',
  'giftDetail.placeholder.code': 'Nhập thông tin',
  'giftDetail.button.submit': 'Kiểm tra QR code',
  'giftDetail.title': 'Nhập QR Code',

  'giftInfo.title': 'Thông tin quà tặng',
  'giftInfo.info': 'Thông tin QR code quà tặng',
  'giftInfo.qrCode': 'Mã QR code quà tặng:',
  'giftInfo.location': 'Địa điểm:',
  'giftInfo.recyclingMachine': 'Máy tái chế xuất QR code:',
  'giftInfo.nameGift': 'Tên quà tặng:',
  'giftInfo.winningTime': 'Thời gian trúng thưởng:',
  'giftInfo.qrCodeExpired': 'Thời gian QR code hết hạn:',
  'giftInfo.qrCodeUsageStatus': 'Trạng thái QR code:',
  'giftInfo.rewardTime': 'Thời gian phát thưởng:',

  'giftInfo.infoCustomer': 'Thông tin khách hàng',
  'giftInfo.numberPhone': 'Số điện thoại:',
  'giftInfo.nameCustomer': 'Tên khách hàng:',
  'button.accept': 'Phát thưởng',

  'giftInfo.status.param':
    '{status,select, 1 {Đã sử dụng} 2 {Chưa sử dụng} 3 {Hết hạn} other {Không xác định}}',

  'additionalInfo.title': 'Bổ sung thông tin',
  'additionalInfo.info': 'Vui lòng nhập thông tin khách hàng nhận thưởng!',
  'additionalInfo.nameGift': 'Tên quà tặng:',
  'additionalInfo.location': 'Địa điểm:',
  'additionalInfo.giftGiver': 'Người phát quà:',
  'additionalInfo.numberPhone': 'Số điện thoại KH:',
  'additionalInfo.placeholder.numberPhone': 'Vui lòng nhập số điện thoại',
  'additionalInfo.nameCustomer': 'Tên khách hàng:',
  'additionalInfo.placeholder.nameCustomer': 'Vui lòng nhập tên khách hàng',
  'additionalInfo.upload': 'Hình ảnh trực tiếp:',
  'additionalInfo.confirm': 'Bạn có muốn xác nhận đã trao phần quà này đến khách hàng không?',
  'additionalInfo.agree': 'Đã thêm thông tin thành công!',
  'additionalInfo.validator-name': 'Vui lòng nhập số điện thoại khách hàng',

  'Mes.RecallHistory.QrCode.NotFound': 'Mã Qr code không tồn tại',
  'giftDetail.validator-code': 'Mã qr code không được để trống',
  'additionalInfo.validator-phone': 'Số điện thoại khách hàng không đúng định dạng',
};