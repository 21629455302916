export default {
  'beginScreen.name': 'Nhập',
  'beginScreen.title': 'Nhập',
  'beginScreen.provinces': 'Tỉnh/Thành phố',
  'beginScreen.districts': 'Quận/Huyện',
  'beginScreen.location': 'Tên địa điểm',
  'beginScreen.createdAt': 'Thời gian diễn ra',
  'beginScreen.campaigns': 'Chiến dịch',
  'beginScreen.startDay': 'Từ ngày',
  'beginScreen.endDay': 'Đến ngày',
  'beginScreen.campaign': 'Chiến dịch',
  'beginScreen.locations': 'Địa điểm',
  'beginScreen.time': 'Thời gian',
  'beginScreen.change': 'Đổi chiến dịch',
  'beginScreen.content.change': 'Bạn có muốn đổi chiến dịch làm việc không?',
  'beginScreen.activities': 'Thống kê hoạt động của bạn',
  'beginScreen.nameGift': 'Tên quà tặng',
  'beginScreen.numberOfDistributed': 'Tổng số lượng đã phát',
  'beginScreen.numberOfNotYetDistributed': 'Số lượng sẽ phát',
  'beginScreen.inventory': 'Lượng quà trong kho',
  'beginScreen.gift': 'Quà tặng',
  'beginScreen.distributed': 'Số quà tặng đã phát',
  'beginScreen.customer': 'Khách hàng',
  'beginScreen.customerInfo': 'Số khách hàng thu thập thông tin',
  'beginScreen.campaignSelect': 'Chọn chiến dịch',
};
