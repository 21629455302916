export default {
  'users.title': 'Người dùng',
  'users.userName': 'Tên đăng nhập',
  'users.fullName': 'Họ và tên',
  'users.phoneNumber': 'Số điện thoại',
  'users.action': 'Hành động',
  'users.update': 'Cập nhật người dùng',
  'users.information': 'Thông tin người dùng',
  'users.password': 'Mật khẩu',
  'users.userType': 'Vai trò',
  'users.create': 'Tạo người dùng',
  'users.delete': 'Xóa người dùng',
  'user.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',
  'users.citizenId': 'Số CMND/CCCD',
  'users.email': 'Email',
  'users.birthday': 'Ngày sinh',
  'users.address': 'Địa chỉ',
  'users.gender': 'Giới tính',
  'users.male': 'Nam',
  'users.female': 'Nữ',
  'users.other': 'Khác',
  'users.countryName': 'Tên quốc gia',

  'profile.edit': 'Chỉnh sửa',
  'users.userType.admin': 'Management',
  'users.userType.accountant': 'Accountant',
  'users.userType.top.up': 'Recharge',
  'transaction.order': 'Purchase',
  'users.userType.order': 'Sell',
  'users.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',

  'users.username': 'Tên đăng nhập',
  'users.name': 'Họ và tên',
  'users.type': 'Loại tài khoản',
  'users.role': 'Vai trò',

  'users.type.id': '{status, select, 1 {Người dùng hệ thống} 2 {PG} other {--}}',

  'users.modal.form.username': 'Tên đăng nhập',
  'users.modal.form.password': 'Mật khẩu',
  'users.modal.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'users.modal.form.confirmPassWord': 'Xác nhận mật khẩu',
  'users.modal.form.confirmPassWord.placeholder': 'Vui lòng nhập xác nhận mật khẩu',
  'users.modal.form.confirmPassWord.required': 'Hai mật khẩu bạn đã nhập không khớp',
  'users.modal.form.name': 'Họ và tên',
  'users.modal.form.gender': 'Giới tính',
  'users.modal.form.gender.placeholder': 'Vui lòng nhập giới tính',
  'users.modal.form.gender.label.1': 'Nam',
  'users.modal.form.gender.label.2': 'Nữ',
  'users.modal.form.gender.label.3': 'Khác',
  'users.modal.form.dob': 'Ngày sinh',
  'users.modal.form.avatar': 'Hình đại diện',
  'users.modal.form.roleId': 'Vai trò',
  'users.modal.form.phoneNumber': 'Số điện thoại',
  'users.phoneNumber.notMatch': 'Số điện thoại không hợp lệ',
  'users.modal.form.email': 'Email',
  'users.modal.form.citizenId': 'CMND/CCCD',
  'users.modal.form.countryName': 'Quốc gia',
  'users.modal.form.address': 'Địa chỉ',
  'users.modal.form.type': 'Loại tài khoản',
  'users.modal.form.type.placeholder': 'Vui lòng nhập loại tài khoản',
  'users.modal.form.type.label.1': 'Người dùng hệ thống',
  'users.modal.form.type.label.2': 'PG',

  'users.info': 'Thông tin nhân viên',
  //User details
  'users.numberOfDistributed': 'Số lượt phát quà',
  'users.numberOfLocations': 'Số địa điểm hoạt động',
  'users.numberOfRecovery': 'Số lượt thu hồi',
  'users.numberOfInforObtained': 'Số khách hàng thu thập được thông tin',
  'users.gender-1': 'Nam',
  'users.gender-2': 'Nữ',
  'users.gender-3': 'Khác',
  //Tab1
  'users.PGDistributeTable.titleTab': 'Lịch sử phát quà',
  'users.PGDistributeTable.title': 'Lịch sử phát quà của PG',
  'users.PGDistributeTable.location.placeholder': 'Chọn địa điểm',
  'users.PGDistributeTable.gift.placeholder': 'Chọn quà tặng',
  'users.PGDistributeTable.qrCodeUsageTime.Start.placeholder': 'Từ ngày',
  'users.PGDistributeTable.qrCodeUsageTime.End.placeholder': 'Đến ngày',

  'users.PGDistributeTable.qrCodeUsageTime': 'Thời gian phát thưởng',
  'users.PGDistributeTable.recyclingMachine': 'Máy tái chế',
  'users.PGDistributeTable.location': 'Địa điểm',
  'users.PGDistributeTable.campaign': 'Chiến dịch',
  'users.PGDistributeTable.qrCode': 'QR code quà tặng',
  'users.PGDistributeTable.gift': 'Tên quà tặng',
  'users.PGDistributeTable.customerPhone': 'Số điện thoại KH',
  'users.PGDistributeTable.customerName': 'Tên khách hàng',
  'users.PGDistributeTable.customerImage': 'Hình ảnh',
  //Tab2
  'users.PGRecallTable.titleTab': 'Lịch sử thu hồi',
  'users.PGRecallTable.title': 'Lịch sử thu hồi quà của PG',
  'users.PGRecallTable.location.placeholder': 'Chọn địa điểm',
  'users.PGRecallTable.gift.placeholder': 'Chọn quà tặng',
  'users.PGRecallTable.qrCodeUsageTime.Start.placeholder': 'Từ ngày',
  'users.PGRecallTable.qrCodeUsageTime.End.placeholder': 'Đến ngày',

  'users.PGRecallTable.createdAt': 'Thời gian thu hồi',
  'users.PGRecallTable.qrCodeUsageTime': 'Thời gian phát thưởng',
  'users.PGRecallTable.campaign': 'Chiến dịch',
  'users.PGRecallTable.location': 'Địa điểm',
  'users.PGRecallTable.recyclingMachine': 'Máy tái chế',
  'users.PGRecallTable.qrCode': 'QR code quà tặng',
  'users.PGRecallTable.gift': 'Tên quà tặng',
  'users.PGRecallTable.customerPhone': 'Số điện thoại KH',
  'users.PGRecallTable.customerName': 'Tên khách hàng',
  'users.PGRecallTable.reason': 'Lý do thu hồi',
};
