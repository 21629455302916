export default {
  'historyScreen.title': 'List of gifts',
  'historyScreen.gift': 'Present:',
  'historyScreen.qrCode': 'qrCode:',
  'historyScreen.time': 'Time:',
  'historyScreen.customer': 'Client:',
  'historyScreen.view-detail': 'See details',
  'historyScreen.recall': 'Recall',

  'webapp.prev': 'Previous page',
  'webapp.next': 'Next page',

  'info-gift.header': 'Details',
  'info-gift.title-1': 'Gift information',
  'info-gift.title-2': 'Reward information',
  'info-gift.campaignName': 'Campaign name',
  'info-gift.qrCode': 'QR code gift code',
  'info-gift.location': 'Location',
  'info-gift.recyclingMachine': 'QR Code Export Recycling Machine',
  'info-gift.giftName': 'Gift name',
  'info-gift.qrCodeUsageTime': 'Reward time',
  'info-gift.phoneNumber': 'Customer phone number',
  'info-gift.customerName': 'Customer name',
  'info-gift.prizeGiver': 'The name of the gift:',
  'info-gift.image': 'Image:',
  'select-gift': 'Present',
  'modal-accept.placeholder': 'Please enter the reason for recovery',
  'modal-accept.title': 'Do you want to recover this gift?',
  'modal-accept.reason': 'Reason for recovery',
  'modal-accept.validator-reason': 'Please enter the reason for recovery',
};
