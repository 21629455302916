import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerCustomer: IRouter = {
  path: '/customer',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customer.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Icon.Users />,
  },
  permissionCode: PermissionEnum.CustomerView,
};
