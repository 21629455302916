import React from 'react';
import { Tool } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerRobot: IRouter = {
  path: '/robot',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'robot.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Tool />,
  },
  permissionCode: [PermissionEnum.RobotsView],
};
