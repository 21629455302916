import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFormRulesAdd: IRouter = {
  path: '/campaigns/rules/form-rules/:type',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'rules.form-rules', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.RulesCreate,
};

export const routerFormRulesEdit: IRouter = {
  path: '/campaigns/rules/form-rules/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'rules.form-rules', //translate here for breadcrumb and sidebar
  permissionCode: [PermissionEnum.RulesViewDetail, PermissionEnum.RulesUpdate],
};
