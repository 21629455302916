export default {
  'server.notFound': 'Không tồn tại',
  'server.networkError': 'Lỗi mạng',
  'Server.AccountEmail.NotExists': 'Thông tin đăng nhập không đúng',
  'Server.AddBeat.Success': 'Thêm thành công',
  'Server.DeleteBeat.Success': 'Xóa thành công',
  'Server.UpdateBeat.Success': 'Cập nhập thành công',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Sai tên đăng nhập hoặc mật khẩu',
  'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  'Mes.Classes.Year.MustHave2Characters': 'Năm học phải có 2 kí tự',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Mã lớp học phải có 2 kí tự',
  'Mes.Classes.Add.Successfully': 'Thêm lớp học thành công',
  'Mes.Questions.Update.Successfully': 'Cập nhật câu hỏi thành công',
  'Mes.Questions.Add.Successfully': 'Thêm câu hỏi thành công',
  'Mes.Users.UpdateSuccessfully': 'Cập nhật thông tin tài khoản thành công',
  'Mes.Users.Add.Successfully': 'Thêm tài khoản thành công',
  'Mes.Users.DeleteUsers.Successfully': 'Xóa thành công',
  'Mes.Researchs.Update.Successfully': 'Cập nhật bài nghiên cứu thành công',
  'Mes.Researchs.Add.Successfully': 'Thêm bài nghiên cứu thành công',
  'Mes.Researchs.Delete.Successfully': 'Xóa bài nghiên cứu thành công',
  'server.data.success': 'Thành công',
  Successful: 'Thành công',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Link khôi phục đã được gửi tới email của bạn',
  'Mes.User.ResetPasswordSuccessfully': 'Đặt lại mật khẩu thành công',
  'Mes.Profile.GetDetailSuccessfully': 'Lấy thông tin tài khoản thành công',
  'Mes.Profile.OldPassword.Wrong': 'Mật khẩu cũ không đúng',
  'Mes.Profile.ChangePasswordSuccessfully': 'Cập nhật mật khẩu thành công',
  'Mes.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Role.CreateSuccessfully': 'Thêm vai trò thành công',
  'Mes.Role.DeleteSuccessfully': 'Xóa vai trò thành công',

  'Mes.RefreshToken.Token.NotFound': 'Không tìm thấy token',
  'Mes.RefreshToken.Token.InValid': 'Refresh token Không hợp lệ',
  'Mes.User.RefreshToken.Expired': 'Refresh token đã hết hạn',
  'Mes.User.RefreshToken.Required': 'Refresh token không để trống',

  'Mes.Role.Code.AlreadyExist': 'Mã vai trò đã tồn tại',
  'Mes.Role.NotFound': 'Không tìm thấy vai trò',
  'Mes.Role.Assigned': 'Không thể xóa vai trò đã được phân quyền',
  'Mes.Role.Create.Successfully': 'Thêm vai trò thành công',
  'Mes.Role.Update.Successfully': 'Cập nhật vai trò thành công',
  'Mes.Role.Update.Failed': 'Cập nhật vai trò thất bại',
  'Mes.Role.Search.Successfully': 'Xem danh sách vai trò thành công',
  'Mes.Role.GetDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Role.Delete.Successfully': 'Xóa vai trò thành công',
  'Mes.Role.Delete.Failed': 'Xóa vai trò thất bại',
  'Mes.Role.Code.Required': 'Mã vai trò là bắt buộc',
  'Mes.Role.Name.Required': 'Tên vai trò là bắt buộc',
  'Mes.Role.Id.InValid': 'Id của vai trò không hợp lệ',

  'Mes.Permission.Code.InValid': 'Mã quyền không hợp lệ',
  'Mes.User.Create.Successfully': 'Thêm người dùng thành công',
  'Mes.User.Update.Successfully': 'Cập nhật người dùng thành công',
  'Mes.User.Update.Failed': 'Cập nhật người dùng thất bại',
  'Mes.User.Search.Successfully': 'Xem danh sách người dùng thành công',
  'Mes.User.GetDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.User.Delete.Successfully': 'Xóa người dùng thành công',
  'Mes.User.Delete.Failed': 'Xóa người dùng thất bại',
  'Mes.User.NotFound': 'Không tìm thấy người dùng',
  'Mes.User.NewPassword.Match.OldPassword': 'Mật khẩu mới không được trùng với mật khẩu cũ',
  'Mes.User.OldPassword.InValid': 'Mật khẩu cũ không hợp lệ',
  'Mes.User.Username.InValid': 'Mã tên đăng nhập không hợp lệ',
  'Mes.User.Password.InValid': 'Mật khẩu không hợp lệ',
  'Mes.User.Email.AlreadyExist': 'Email đã tồn tại',
  'Mes.User.Username.AlreadyExist': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Type.Required': 'Loại người dùng là bắt buộc',
  'Mes.User.Type.InValid': 'Loại người dùng không hợp lệ',
  'Mes.User.RoleId.Required': 'RoleId là bắt buộc',
  'Mes.User.Email.Required': 'Email của người dùng là bắt buộc',
  'Mes.User.Email.InValid': 'Email của người dùng không hợp lệ',
  'Mes.User.Username.Required': 'Tên đăng nhập là bắt buộc',
  'Mes.User.Dob.Required': 'Ngày sinh là bắt buộc',
  'Mes.User.Name.Required': 'Tên người dùng là bắt buộc',
  'Mes.User.Gender.Required': 'Giới tính là bắt buộc',
  'Mes.User.Gender.InValid': 'Giới tính không hợp lẹ',
  'Mes.User.PhoneNumber.InValid': 'Số điện thoại không hợp lệ',
  'Mes.User.Avatar.InValid': 'Ảnh đại diện không hợp lệ',
  'Mes.User.OldPassword.Required': 'Mật khẩu cũ là bắt buộc',
  'Mes.User.Password.Required': 'Mật khẩu là bắt buộc',

  'Mes.Gift.Create.Successfully': 'Thêm quà thành công',
  'Mes.Gift.Update.Successfully': 'Cập nhật quà thành công',
  'Mes.Gift.Update.Failed': 'Cập nhật quà thất bại',
  'Mes.Gift.Search.Successfully': 'Xem danh sách quà thành công',
  'Mes.Gift.GetDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Gift.Delete.Successfully': 'Xóa quà thành công',
  'Mes.Gift.Delete.Failed': 'Xóa quà thất bại',
  'Mes.Gift.NotFound': 'Không tìm thấy quà',
  'Mes.Gift.Name.Required': 'Tên của quà là bắt buộc',
  'Mes.Gift.Unit.Required': 'Đơn vị của quà là bắt buộc',
  'Mes.Gift.Illustration.Required': 'Ảnh của quà là bắt buộc',
  'Mes.Gift.Illustration.InValid': 'Ảnh của quà không hợp lệ',
  'Mes.Gift.Used': 'Quà đã được sử dụng',

  'Mes.Location.NotFound': 'Không tìm thấy địa điểm này',
  'Mes.Location.Name.Required': 'Tên địa điểm là bắt buộc',
  'Mes.Location.Name.OverLength': 'Tên vượt quá kí tự cho phép',
  'Mes.Location.Address.Required': 'Địa chỉ là bắt buộc',
  'Mes.Location.Address.OverLength': 'Địa chỉ vượt quá kí tự cho phép',
  'Mes.Location.ProvinceCode.Required': 'Mã tỉnh là bắt buộc',
  'Mes.Location.DistrictCode.Required': 'Mã quận là bắt buộc',
  'Mes.Location.CommuneCode.Required': 'Mã huyện là bắt buộc',
  'Mes.Addresses.NotFound': 'Không tìm thấy địa chỉ',
  'Mes.Location.Create.Successfully': 'Thêm địa điểm thành công',
  'Mes.Location.Update.Successfully': 'Chỉnh sửa địa điểm thành công',
  'Mes.Location.Delete.Successfully': 'Xóa địa điểm thành công',
  'Mes.Location.GetDetail.Successfully': 'Lấy chi tiết địa điểm thành công',
  'Mes.Location.Search.Successfully': 'Tìm kiếm địa điểm thành công',

  'Mes.Area.Create.Successfully': 'Thêm khu vực thành công',
  'Mes.Area.Update.Successfully': 'Cập nhật khu vực thành công',
  'Mes.Area.Delete.Successfully': 'Xóa khu vực thành công',
  'Mes.Area.NotFound': 'Không tìm thấy khu vực này',
  'Mes.Area.Name.Required': 'Tên là bắt buộc',
  'Mes.Area.Name.OverLength': 'Tên vượt quá kí tự cho phép',
  'Mes.Area.Decription.OverLength': 'Mô tả vượt quá kí tự cho phép',
  'Mes.Province.Code.Required': 'Mã tỉnh là bắt buộc',
  'Mes.Province.Code.Duplicate': 'Mã tỉnh bị trùng lặp',
  'Mes.Province.NotFound': 'Không tìm thấy tỉnh này',

  'Mes.Robots.Code.IsNotAllowedToUpdate': 'Mã robot không được phép cập nhật',
  'Mes.Robots.Id.IsRequired': 'Id robot không được trống',
  'Mes.Robots.Id.IdIsNotfound': 'Id robot không tìm thấy',
  'Mes.Robots.Code.IsRequired': 'Mã robot bắt buộc',
  'Mes.Robots.Code.LengthIsInvalid': 'Độ dài của mã robot không hợp lệ',
  'Mes.Robots.Code.IsAvailable': 'Mã robot đã tồn tại',
  'Mes.Robots.Name.IsRequired': 'Tên robot không được trống',
  'Mes.Robots.Name.LengthIsInvalid': 'Độ dài của tên robot không hợp lệ',
  'Mes.Robots.Name.IsAvailable': 'Tên robot đã có sẳn',
  'Mes.Robots.MadeIn.IsRequired': 'Nơi sản xuất không được trống',
  'Mes.Robots.MadeIn.LengthIsInvalid': 'Độ dài của Nơi sản xuất không hợp lệ',
  'Mes.Robots.Description.IsRequired': 'Mô tả robot không hợp lệ',
  'Mes.Robots.Description.LengthIsInvalid': 'Độ dài của mô tả robot không hợp lệ',
  'Mes.Robots.RobotType.IsRequired': 'Loại robot không được trống',
  'Mes.Robots.RobotType.IsInvalid': 'Loại robot không hợp lệ',
  'Mes.Robots.GetDetail.Successfully': 'Lấy chi tiết robot thành công',
  'Mes.Robots.Create.Successfully': 'Thêm robot thành công',
  'Mes.Robots.Update.Successfully': 'Cập nhật robot thành công',
  'Mes.Robots.Delete.Successfully': 'Xóa robot thành công',
  'Mes.Robots.Search.Successfully': 'TÌm kiếm robot thàng công',
  'Mes.Robots.RobotType.NotAllowedToUpdate': 'Loại robot này không được phép cập nhật',

  'Mes.HistoryFullContainers.GetDetail.Successfully': 'Lấy chi tiết lịch sử thùng chứa thành công',
  'Mes.HistoryFullContainers.Create.Successfully': 'Thêm lịch sử thùng chứa thành công',
  'Mes.HistoryFullContainers.Update.Successfully': 'Cập nhật lịch sử thùng chứa thành công',
  'Mes.HistoryFullContainers.Delete.Successfully': 'Xóa lịch sử thùng chứa thành công',
  'Mes.HistoryFullContainers.RecyclingMachineId.IsRequired': 'Id của máy tái chế không được trống',
  'Mes.HistoryFullContainers.RecyclingMachineId.IsAvailable': 'Id của máy tái chế đã có sẳn',
  'Mes.HistoryFullContainers.RecyclingMachineId.IsNotfound': 'Id của máy tái chế không tìm thấy',
  'Mes.HistoryFullContainers.TotalValid.IsRequired': 'Tổng số lon heniken không được trống',
  'Mes.HistoryFullContainers.Search.Successfully': 'Tìm kiếm thành công lịch sử thùng chứa',
  'Mes.HistoryFullContainers.TotalInValid.IsRequired': 'Tổng số lon khác không được trống',

  'Mes.ReallHistories.RecyclingMachineId.IsRequired': 'Id của máy tái chế không được trống',
  'Mes.ReallHistories.RecyclingMachineId.IsAvailable': 'Id của máy tái chế đã đã tồn tại',
  'Mes.ReallHistories.RecyclingMachineId.IsNotfound': 'Id của máy tái chế không tìm thấy',
  'Mes.ReallHistories.GetDetail.Successfully': 'Lấy thành công chi tiết máy tái chế',
  'Mes.ReallHistories.Create.Successfully': 'Thêm máy tái chế thành công',
  'Mes.ReallHistories.Update.Successfully': 'Cập nhật máy tái chế thành công',
  'Mes.ReallHistories.Delete.Successfully': 'Xóa máy tái chế thành công',
  'Mes.ReallHistories.TotalValid.IsRequired': 'Tổng số lon heniken không được trống',
  'Mes.ReallHistories.Search.Successfully': 'Tìm kiếm máy tái chế thành công',
  'Mes.ReallHistories.TotalInValid.IsRequired': 'Tổng số lon khác không được trống',

  'Mes.Devices.GetDetail.Successfully': 'Lấy chi tiết thiết bị thành công',

  'Mes.RecyclingMachines.Create.Successfully': 'Thêm máy tái chế thành công',
  'Mes.RecyclingMachines.Update.Successfully': 'Cập nhật máy tái chế thành công',
  'Mes.RecyclingMachines.Delete.Successfully': 'Xóa máy tái chế thành công',
  'Mes.RecyclingMachines.Id.IsRequired': 'Id của máy tái chế không được trống',
  'Mes.RecyclingMachines.Id.IdIsNotfound': 'Id của máy tái chế không được tìm thấy',
  'Mes.RecyclingMachines.Code.IsRequired': 'Mã của máy tái chế không được trống',
  'Mes.RecyclingMachines.Code.LengthIsInvalid': 'Độ dài của mã máy tái chế không hợp lệ',
  'Mes.RecyclingMachines.Code.IsAvailable': 'Mã máy tái chế đã tồn tại',
  'Mes.RecyclingMachines.Name.IsRequired': 'Tên của máy tái chế không được trống',
  'Mes.RecyclingMachines.Name.LengthIsInvalid': 'Độ dài của tên máy tái chế không hợp lệ',
  'Mes.RecyclingMachines.Name.IsAvailable': 'Tên máy tái chế đã đã tồn tại',
  'Mes.RecyclingMachines.MadeIn.IsRequired': 'Nơi sản xuất không được trống',
  'Mes.RecyclingMachines.MadeIn.LengthIsInvalid': 'Độ dài của nơi sản xuất không hợp lệ',
  'Mes.RecyclingMachines.Description.IsRequired': 'Mô tả của máy tái chế không được trống',
  'Mes.RecyclingMachines.Description.LengthIsInvalid': 'Độ dài của mô tả máy tái chế không hợp lệ',
  'Mes.RecyclingMachines.Search.Successfully': 'Tìm kiếm thành công máy tái chế',
  'Mes.RecyclingMachines.CreateRecallHistory.Successfully':
    'Thêm lịch sử hoạt động máy tái chế thành công',
  'Mes.RecyclingMachines.CreateHistoryFullContainer.Successfully':
    'Thêm lịch sử đầy thùng chứa thành công',
  'Mes.RecyclingMachines.SearchRecallHistory.Successfully':
    'Tìm kiếm lịch sử hoạt động máy tái chế thành công',
  'Mes.RecyclingMachines.SearchHistoryFullContainer.Successfully':
    'Tìm kiếm lịch sử đầy thùng chứa thành công',
  'Mes.RecyclingMachines.Code.IsNotAllowedToUpdate': 'Không được cập nhật mã máy tái chế',
  'Mes.RecyclingMachines.IsNotAllowedToDelete': 'Không được phép xóa máy tái chế',
  'Mes.RecyclingMachines.Code.Required': 'Mã máy tái chế không được để trống',
  'Mes.RecyclingMachines.Name.Required': 'Tên máy tái chế không được để trống',
  'Mes.RecyclingMachines.IsUsed': 'Không thể xóa máy tái chế đã được sử dụng',
  'Mes.RecyclingMachines.Code.AlreadyExist': 'Mã máy tái chế đã tồn tại',

  'Mes.Rule.Create.Successfully': 'Tạo quy tắc thành công',
  'Mes.Rule.Update.Successfully': 'Cập nhật quy tắc thành công',
  'Mes.Rule.Update.Failed': 'Cập nhật quy tắc thất bại',
  'Mes.Rule.Search.Successfully': 'Tìm kiếm quy tắc thành công',
  'Mes.Rule.GetDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Rule.Delete.Successfully': 'Xóa quy tắc thành công',
  'Mes.Rule.Delete.Failed': 'Xóa quy tắc thất bại',
  'Mes.Rule.NotFound': 'Không tìm thấy quy tắc',
  'Mes.Rule.IsUsing': 'Quy tắc đang được sử dụng',
  'Mes.Rule.Name.Required': 'Tên của quy tắc là bắt buộc',
  'Mes.Rule.RuleSettings.InValid': 'Phạm vi điểm tích lũy không hợp lệ',
  'Mes.Rule.RuleSettings.Required': 'Phạm vi điểm tích lũy là bắt buộc',

  'Mes.RuleSetting.ScoreFrom.Required': 'Điểm bắt đầu là bắt buộc',
  'Mes.RuleSetting.ScoreFrom.InValid': 'Điểm bắt đầu không hợp lệ',
  'Mes.RuleSetting.ScoreTo.Required': 'Điểm kết thúc là bắt buộc',
  'Mes.RuleSetting.ScoreTo.InValid': 'Điểm kết thúc không hợp lệ',
  'Mes.RuleSetting.RuleGiftSettings.Required': 'Nội dung quà tặng là bắt buộc',
  'Mes.RuleSetting.RuleGiftSettings.InValid': 'Nội dung quà tặng không hợp lệ',

  'Mes.RuleGiftSetting.GiftId.Duplicate':
    'Quà tặng trong một phạm vi điểm tích lũy không được trùng',
  'Mes.RuleGiftSetting.GiftId.Required': 'Id quà là bắt buộc',
  'Mes.RuleGiftSetting.GiftId.InValid': 'Id quà không hợp lệ',
  'Mes.RuleGiftSetting.GiftId.MustBeEmpty': 'Không được có Id quà tặng',
  'Mes.RuleGiftSetting.Type.Required': 'Loại thiết lập quà tặng là bắt buộc',
  'Mes.RuleGiftSetting.Type.InValid': 'Loại thiết lập quà tặng không hợp lệ',
  'Mes.RuleGiftSetting.Percent.Required': 'Phần trăm là bắt buộc',
  'Mes.RuleGiftSetting.Percent.InValid': 'Phần trăm không hợp lệ',

  'Mes.Campaign.Update.Successfully': 'Cập nhật chiến dịch thành công',
  'Mes.Campaign.Delete.Successfully': 'Xóa chiến dịch thành công',
  'Mes.Campaign.Create.Successfully': 'Tạo chiến dịch thành công',
  'Mes.Campaign.LocationId.IsUsed': 'Địa điểm đã được sử dụng',
  'Mes.Campaign.RecyclingMachineIds.IsUsed': 'Máy tái chế đã được sử dụng',
  'Mes.Campaign.Update.CampaignIsEnd': 'Không thể cập nhật chiến dịch đã kết thúc',
  'Mes.Campaign.Delete.CampaignIsStarted': 'Không thể xóa chiến dịch đã diễn ra',
  'Mes.Campaign.TakePlaceFrom.InValid': 'Thời gian diễn ra chiến dịch không hợp lệ.',
  'Mes.Campaign.TakePlaceFrom.CampaignIsStarted': 'Chiến dịch đã bắt đầu.',
  'Mes.Campaign.Limit.InValid': 'Số lượng không được phép nhỏ hơn số quà đã phát',

  'Mes.Customer.Update.Successfully': 'Cập nhật khách hàng thành công',
  'Mes.Customer.Name.Required': 'Vui lòng nhập tên khách hàng',

  'Mes.Province.IsUsing': 'Tỉnh thành đã thuộc khu vực khác',

  'Mes.RecallHistory.QrCode.NotFound': 'Không tìm thấy Qrcode',
  'Mes.RecallHistory.PrizeGiver.Delivered': 'Quà tặng đã phát thưởng',
  'Mes.RecallHistory.QrCode.Expires': 'Qrcode hết hạn',
  'Mes.RecallHistory.Reason.NotEmpty': 'Lý do thu hồi không được để trống',
  'Mes.RecallHistory.Reason.OverLength': 'Lý do quá độ dài quy định',
  'Mes.RecallHistory.GetDetailByQrCode.Successfully': 'Lấy thông tin thành công',
  'Mes.RecallHistory.GetDetail.Successfully': 'Lấy thông tin thành công',
  'Mes.RecallHistory.Id.NotFound': 'Không tìm thấy Id',
  'Mes.RecallHistory.Award.Successfully': 'Đổi thưởng thành công',
  'Mes.RecallHistory.Recovery.Successfully': 'Thu hồi quà tặng đã phát thành công',
  'Mes.Campaign.PrizeGiverGiftGivingHistoriesSearch.Successfully':
    'Lấy thông tin quà tặng thành công',
  'Mes.User.PrizeGave': 'Không thể xóa người dùng đang hoạt động',
  'Mes.Location.IsUsing':	'Không thể xóa địa điểm đang được sử dụng',
  'Mes.Campaign.Name.Required':	'Tên chiến dịch không được để trống',
  'Mes.Robots.Code.AlreadyExist': 'Trùng mã robot',
  'Mes.Area.IsUsing':'Tỉnh thành trực thuộc được chọn đã thuộc khu vực khác',
  'Mes.Area.Description.OverLength': 'Mô tả vượt quá kí tự cho phép',
  'Mes.RecallHistory.Update.Failed': 'Cập nhật thông tin thất bại',
  'Mes.Customers.Image.InValidType': 'Ảnh không đúng định dạng',
  'Mes.Campaign.TakePlaceTo.InValid': 'Thời gian kết thúc không hợp lệ',
};
