export default {
  'users.title': 'User',
  'users.userName': 'User name',
  'users.fullName': 'Full name',
  'users.phoneNumber': 'Phone number',
  'users.action': 'Act',
  'users.update': 'Update user',
  'users.information': 'User information',
  'users.password': 'Password',
  'users.userType': 'Role',
  'users.create': 'Create user',
  'users.delete': 'Delete user',
  'user.content.delete': 'Are you sure you want to delete this user?',
  'users.citizenId': 'Indentify',
  'users.email': 'Email',
  'users.birthday': 'Date of birth',
  'users.address': 'Address',
  'users.gender': 'Gender',
  'users.male': 'Male',
  'users.female': 'Female',
  'users.other': 'Other',
  'users.countryName': 'Country name',

  'profile.edit': 'Edit',
  'users.userType.admin': 'Management',
  'users.userType.accountant': 'Accountant',
  'users.userType.top.up': 'Recharge',
  'transaction.order': 'Purchase',
  'users.userType.order': 'Sell',
  'users.content.delete': 'Are you sure want to delete this user?',

  'users.username': 'User name',
  'users.name': 'Full name',
  'users.type': 'Account Type',
  'users.role': 'Role',
  'users.type.id': '{status, select, 1 {System users} 2 {PG} other {--}}',

  'users.modal.form.username': 'User name',
  'users.modal.form.password': 'Password',
  'users.modal.form.password.placeholder': 'Please enter a password',
  'users.modal.form.confirmPassWord': 'Confirm password',
  'users.modal.form.confirmPassWord.placeholder': 'Please enter password confirmation',
  'users.modal.form.confirmPassWord.required': 'The two passwords you entered do not match',
  'users.modal.form.name': 'Full name',
  'users.modal.form.gender': 'Gender',
  'users.modal.form.gender.placeholder': 'Please enter gender',
  'users.modal.form.gender.label.1': 'Male',
  'users.modal.form.gender.label.2': 'Female',
  'users.modal.form.gender.label.3': 'Other',
  'users.modal.form.dob': 'Date of birth',
  'users.modal.form.avatar': 'Avatar',
  'users.modal.form.roleId': 'Role',
  'users.modal.form.phoneNumber': 'Phone number',
  'users.phoneNumber.notMatch': 'Invalid phone number',
  'users.modal.form.email': 'Email',
  'users.modal.form.citizenId': 'ID',
  'users.modal.form.countryName': 'Nation',
  'users.modal.form.address': 'Address',
  'users.modal.form.type': 'Account type',
  'users.modal.form.type.placeholder': 'Please enter account type',
  'users.modal.form.type.label.1': 'System users',
  'users.modal.form.type.label.2': 'PG',

  'users.info': 'User information',
  //User details
  'users.numberOfDistributed': 'Number of gift distributions',
  'users.numberOfLocations': 'Number of operating locations',
  'users.numberOfRecovery': 'Number of gift retrieved',
  'users.numberOfInforObtained': 'Number of customer information collected',
  'users.gender-1': 'Male',
  'users.gender-2': 'Female',
  'users.gender-3': 'Other',
  //Tab1
  'users.PGDistributeTable.titleTab': 'Gift distribution history',
  'users.PGDistributeTable.title': 'PG gift distribution history',
  'users.PGDistributeTable.location.placeholder': 'Select location',
  'users.PGDistributeTable.gift.placeholder': 'Select gift',
  'users.PGDistributeTable.qrCodeUsageTime.Start.placeholder': 'From date',
  'users.PGDistributeTable.qrCodeUsageTime.End.placeholder': 'To date',

  'users.PGDistributeTable.qrCodeUsageTime': 'Gift winning time',
  'users.PGDistributeTable.recyclingMachine': 'Recycling machine',
  'users.PGDistributeTable.location': 'Location',
  'users.PGDistributeTable.campaign': 'Campaign',
  'users.PGDistributeTable.qrCode': 'Gift QR code',
  'users.PGDistributeTable.gift': 'Gift name',
  'users.PGDistributeTable.customerPhone': 'Customer phone number',
  'users.PGDistributeTable.customerName': 'Customer name',
  'users.PGDistributeTable.customerImage': 'Image',
  //Tab2
  'users.PGRecallTable.titleTab': 'PG Gift retrieval history',
  'users.PGRecallTable.title': 'PG gift retrieval history',
  'users.PGRecallTable.location.placeholder': 'Select location',
  'users.PGRecallTable.gift.placeholder': 'Select gift',
  'users.PGRecallTable.qrCodeUsageTime.Start.placeholder': 'From date',
  'users.PGRecallTable.qrCodeUsageTime.End.placeholder': 'To date',

  'users.PGRecallTable.createdAt': 'Retrieval time',
  'users.PGRecallTable.qrCodeUsageTime': 'Gift winning time',
  'users.PGRecallTable.campaign': 'Campaign',
  'users.PGRecallTable.location': 'Location',
  'users.PGRecallTable.recyclingMachine': 'Recycling machine',
  'users.PGRecallTable.qrCode': 'Gift QR code',
  'users.PGRecallTable.gift': 'Gift name',
  'users.PGRecallTable.customerPhone': 'Customer phone number',
  'users.PGRecallTable.customerName': 'Customer name',
  'users.PGRecallTable.reason': 'Reason for retrieval',
};
