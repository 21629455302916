import React from 'react';

import { IRouter } from '@routers/interface';

export const routerGiftHistory: IRouter = {
  path: '/gift-history-screen',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gift-history.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
