import React from 'react';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerRoles: IRouter = {
  path: '/setting/roles',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'roles.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.RolesView,
};
