export default {
  'rules.title': 'Rule win prize',
  'rules.name': 'Rule name',
  'rules.description': 'Description',
  'rules.numberOfCampaignsToApply': 'Number of campaigns to apply',
  'rules.createdAt': 'Create At',
  'rules.action': 'Action',
  'rules.content.delete': 'Do you want to delete this rule?',
  'rules.create': 'Create new rule',
  'rules.scoreFrom': 'From',
  'rules.scoreTo': 'To',
  'rules.rangePoint': 'Range point From:',
  'rules.from': 'From',
  'rules.deco': 'To',
  'rules.to': 'To',
  'rules.nameGift': 'Name gift',
  'rules.not-win': 'Not win',
  'rules.addGift': 'Add gift',
  'rules.addRule': 'Add range point',
  'rules.placeholder.name': 'Please enter name rule',
  'rules.placeholder.description': 'Please enter description',
  'rules.rate': 'Rate',
  'rules.info': 'Rule information',
  'rules.add': 'Add rule',
  'rules.edit': 'Update rule',
  'rules.btn-edit': 'Update',
  'rules.btn-add': 'Add',
  'rules.setRule': 'Set rule',
  'rules.required-score': 'Enter point',
  'rules.content-gift': 'Gift content',
  'rules.validator-name': 'Rule name must have character',
  'rules.validator-gift': 'Please select gift',

  'rules.sub-range': 'Subsidiary range',
  'rules.placeholder.sub-range': 'Please select subsidiary range',
};
