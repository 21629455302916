export default {
  'robot.title': 'Quản lý Robot',
  'robot.code': 'Mã Robot',
  'robot.name': 'Tên Robot',
  'robot.robotType': 'Loại Robot',
  'robot.location': 'Địa điểm hoạt động',
  'robot.lastAccessAt': 'Lần truy cập cuối cùng',
  'robot.batteryCapacity': 'Lượng Pin còn lại',
  'robot.activeStatus': 'Trạng thái',
  'robot.createdAt': 'Ngày thêm',
  'robot.action': 'Hành động',
  'robot.madeIn': 'Nơi sản xuất',
  'robot.create': 'Thêm robot mới',
  'robot.description': 'Mô tả',
  'robot.statusOnline': 'Online',
  'robot.statusOffline': 'Offline',
  'robot.modal-createdAt': 'Ngày thêm vào hệ thống',
  'robot.modal-lastAccessAt': 'Lần truy cập gần nhất',
  'robot.modal-location': 'Địa điểm hoạt động hiện tại',
  'robot.information': 'Thông tin Robot',
  'robot.content.delete': 'Bạn có xác nhận xóa Robot này?',
  'robot.update': 'Cập nhật thông tin Robot',
  'robot.silver-bot': 'Robot tương tác',
  'robot.delivery-box': 'Robot vận chuyển',
  'robot.statusOffline.id': '{status, select, 1 {Online} 2 {Offline} other {Không xác định}}',
  'robot.modal-locationId': 'Địa điểm hoạt động',
  'robot.startDay': 'Ngày bắt đầu',
  'robot.endDay': 'Ngày kết thúc',
  'robot.validator-code': 'Mã robot không được để trống',
  'robot.validator-name': 'Tên robot không được để trống',
};
