export default {
  'report.name': 'Report',
  'robotSilverbot.name': 'Robot Silverbot',
  'deliveryRobot.name': 'Robot Delivery',
  'recyclingMachine.name': 'Recycling machine',
  'giftStatistics.name': 'Gift statistics',

  'filter.title': 'Please choose the information to report!',
  'filter.date': 'Report time',
  'filter.location': 'Location',
};
