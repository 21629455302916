import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerRules: IRouter = {
  path: '/rules',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'rules.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.RulesView],
};
