export default {
  //tab1
  'customer.title': 'Customer management',
  'customer.card.percentageHaveInfor': 'Percentage of customers providing information',
  'customer.card.numberOfInforObtained': 'Total number of customers providing information',
  'customer.card.customer': 'Customer',

  'customer.phoneNumber': 'Phone number',
  'customer.name': 'Customer name',
  'customer.numberOfWon': 'Number of prizes won',
  'customer.numberOfLocations': 'Number of operating locations',
  'customer.obtainedAmount': 'Total cans obtained',
  'customer.validAmount': 'Total Heineken cans obtained',
  'customer.invalidAmount': 'Total other obtained cans',

  //tab2
  'customerInfo.name': 'Customer details',
  'customer.attach.title': 'Customer interaction information',
  'customerInfo.approachTimeFilter': 'Interaction Time',
  'customerInfo.qrCodeUsageTimeFilter': 'Reward Distribution Time',
  'customerInfo.approachTime': 'Approach Time',
  'customerInfo.campaign.name': 'Campaign name',
  'customerInfo.recyclingMachine.name': 'Recycling machine',
  'customerInfo.campaign.location': 'Location',
  'customerInfo.totalObtained': 'Number of Ordered Cans',
  'customerInfo.totalValid': 'Number of Heineken Cans',
  'customerInfo.totalInValid': 'Number of Other Cans',
  'customerInfo.winningTime': 'Winning Time',
  'customerInfo.qrCode': 'QR Code',
  'customerInfo.gift.name': 'Gift',
  'customerInfo.qrCodeUsageTime': 'Winning time',
  'customerInfo.prizeGiver.name': 'Prize giver',
  'customerInfo.image': 'Image',

  //share
  'customer.startDay': 'From date',
  'customer.endDay': 'To date',
  'customer.action': 'Action',
  'customer.update': 'Update Customer',
};
