export default {
  //resume

  // tab general infomation
  'campaignGiftManage.totalReach': 'Tổng số lượt tiếp cận',
  'campaignGiftManage.totalRetrievedCans': 'Số lon thu hồi',
  'campaignGiftManage.totalHeinekenCans': 'Số lon Heineken',
  'campaignGiftManage.totalOtherCans': 'Số lon khác',
  'campaignGiftManage.can': 'Lon',
  'campaignGiftManage.thousand-can': 'Nghìn lon',
  'campaignGiftManage.million-can': 'Triệu lon',
  'campaignGiftManage.reach': 'Lượt tiếp cận',
  'campaignGiftManage.thousand-reach': 'Nghìn lượt tiếp cận',
  'campaignGiftManage.million-reach': 'Triệu lượt tiếp cận',

  'campaignGiftManage.createdQRCode': 'Tổng số QR code đã tạo',
  'campaignGiftManage.createdQRCode_unit': 'QR code',
  'campaignGiftManage.numberOfDistributed_unit': 'Quà tặng',
  'campaignGiftManage.numberOfExpired_unit': 'Quà tặng',
  'campaignGiftManage.numberOfCustomer': 'Số khách hàng có thông tin',
  'campaignGiftManage.numberOfCustomer_unit': 'Khách hàng',

  'campaignGiftManage.campaignGiftList': 'Danh sách quà tặng của chiến dịch',

  'campaignGiftManage.giftImage': 'Hình ảnh',
  'campaignGiftManage.giftName': 'Tên quà tặng',
  'campaignGiftManage.giftUnit': 'Đơn vị',
  'campaignGiftManage.limit': 'Tổng số quà tặng',
  'campaignGiftManage.numberOfWon': 'Tổng số quà tặng đã trúng',
  'campaignGiftManage.numberOfDistributed': 'Tổng số quà tặng đã phát',
  'campaignGiftManage.numberOfNotYetDistributed': 'Tổng số quà tặng chưa phát',
  'campaignGiftManage.numberOfExpired': 'Tổng số quà tặng hết hạn',
  'campaignGiftManage.inventory': 'Tổng số quà tặng hiện tại tại kho',
  'campaignGiftManage.numberOfAvailable': 'Số quà tặng khả dụng',

  'campaignGiftManage.pauseCampaign': 'Tạm ngưng chiến dịch',
  'campaignGiftManage.continueCampaign': 'Mở lại chiến dịch',

  //tab history comparison
  'luckyDrawHistory.recallHistories': 'Lịch sử tiếp nhận lon và quay thưởng của khách hàng',
  'luckyDrawHistory.approachTime': 'Thời gian tiếp cận',
  'luckyDrawHistory.totalObtained': 'Tổng số lượng lon được thu hồi',
  'luckyDrawHistory.totalValid': 'Số lon Heineken',
  'luckyDrawHistory.totalInValid': 'Số lon khác',
  'luckyDrawHistory.totalConversionPoint': 'Tổng điểm quy đổi',
  'luckyDrawHistory.receivedGift': 'Quà tặng nhận được',
  'luckyDrawHistory.winningTime': 'Thời gian trúng thưởng',
  'luckyDrawHistory.qrCode': 'Mã QR code',
  'luckyDrawHistory.qrCodeUsageTime': 'Thời gian sử dụng QR code',
  'luckyDrawHistory.prizeGiver': 'Người phát quà',

  'luckyDrawHistory.recyclingMachine': 'Máy tái chế',
  'luckyDrawHistory.gift': 'Quà tặng',
  'luckyDrawHistory.QRCodePrintingTime': 'Thời gian in QR code',
  'luckyDrawHistory.qrCodeUsageStatus': 'Trạng thái sử dụng QR code',
  'luckyDrawHistory.qrCodeUsageStatus1': 'Đã sử dụng',
  'luckyDrawHistory.qrCodeUsageStatus2': 'Chưa sử dụng',
  'luckyDrawHistory.qrCodeUsageStatus3': 'Hết hạn',
  'luckyDrawHistory.notSpinYet': 'Không quay',
  'luckyDrawHistory.spinFail': 'Không trúng thưởng',

  'giftDistributionHistory.PGGivingGiftHistory': 'Lịch sử phát quà của PG',
  'giftDistributionHistory.approachTime': 'Thời gian tiếp cận',
  'giftDistributionHistory.totalObtained': 'Tổng số lượng lon được thu hồi',
  'giftDistributionHistory.totalValid': 'Số lon Heineken',
  'giftDistributionHistory.totalInValid': 'Số lon khác',
  'giftDistributionHistory.totalConversionPoint': 'Tổng điểm quy đổi',
  'giftDistributionHistory.receivedGift': 'Quà tặng nhận được',
  'giftDistributionHistory.winningTime': 'Thời gian trúng thưởng',
  'giftDistributionHistory.qrCode': 'Mã QR code',
  'giftDistributionHistory.qrCodeUsageTime': 'Thời gian sử dụng QR code',
  'giftDistributionHistory.prizeGiver': 'Người phát quà',
  'giftDistributionHistory.customerPhone': 'Số điện thoại KH',
  'giftDistributionHistory.customerName': 'Tên khách hàng',
  'giftDistributionHistory.customerImage': 'Hình ảnh',

  'giftDistributionHistory.givingGiftTime': 'Gift giving time',
  'giftDistributionHistory.recyclingMachine': 'Máy tái chế',
  'giftDistributionHistory.gift': 'Quà',

  //modal update gift quantity
  'campaignGiftManage.updateGiftQuantityTitle': 'Cập nhật số lượng quà tặng',

  //modal gift quantity update history
  'campaignGiftManage.updateHistoryTitle': 'Lịch sử cập nhật quà tặng',
  'campaignGiftManage.updateTime': 'Thời gian cập nhật',

  'campaignGiftManage.quantityBeforeUpdate': 'Số quà trước khi cập nhật',
  'campaignGiftManage.quantityAfterUpdate': 'Số quà sau khi cập nhật',
  'campaignGiftManage.inventoryAfterUpdate': 'Số quà trong kho sau khi cập nhật',
  'campaignGiftManage.updateAuthor': 'Người cập nhật',

  //modal recall history
  'campaignGiftManage.recallInfo': 'Thông tin trúng thưởng',
  'campaignGiftManage.location': 'Địa điểm',
  'campaignGiftManage.recallHistory': 'Lịch sử trúng thưởng',

  //shared

  'campaignGiftManage.action': 'Hành động',
  'campaignGiftManage.confirm': 'Xác nhận',
  'campaignGiftManage.cancel': 'Hủy',
  'campaignGiftManage.agree': 'Đồng ý',
  'campaignGiftManage.confirmPauseCamp': 'Bạn có xác nhận tạm ngưng chiến dịch này không?',
  'campaignGiftManage.confirmContinueCamp': 'Bạn có xác nhận mở lại chiến dịch này không',
  'campaignGiftManage.confirmUpdateGiftQuantity': 'Bạn có xác nhận cập nhật số lượng quà tặng này',

  'campaignGiftManage.selectMachine': 'Chọn máy',
  'campaignGiftManage.selectGift': 'Chọn quà',
  'campaignGiftManage.selectStatus': 'Chọn trạng thái',

  'campaignGiftManage.qrCodeUsageStatus.id':
    '{status, select, 1 {Đã sử dụng} 2 {Chưa sử dụng} 3 {Hết hạn} other {Undefined}}',
  'campaignGiftManage.recyclingMachine': 'Máy tái chế',
  'campaignGiftManage.startDay': 'Bắt đầu',
  'campaignGiftManage.endDay': 'Kết thúc',
  'campaignGiftManage.campaign': 'Chiến dịch',
  //trash
  'campaignGiftManage.recallHistories': 'Lịch sử thu hồi lon và quay thưởng của khách hàng',
  'campaignGiftManage.approachTime': 'Thời gian tiếp cận',
  'campaignGiftManage.totalObtained': 'Tổng số lượng lon được thu hồi',
  'campaignGiftManage.totalValid': 'Số lon Heineken',
  'campaignGiftManage.totalInValid': 'Số lon khác',
  'campaignGiftManage.totalConversionPoint': 'Tổng điểm quy đổi',
  'campaignGiftManage.receivedGift': 'Quà tặng nhận được',
  'campaignGiftManage.winningTime': 'Thời gian trúng thưởng',
  'campaignGiftManage.qrCode': 'Mã QR code',
  'campaignGiftManage.qrCodeUsageTime': 'Thời gian sử dụng QR code',
  'campaignGiftManage.prizeGiver': 'Người phát quà',
  'campaignGiftManage.gift': 'Quà tặng',
  'campaignGiftManage.QRCodePrintingTime': 'Thời gian in QR code',
  'campaignGiftManage.qrCodeUsageStatus': 'Trạng thái sử dụng QR code',
  'campaignGiftManage.qrCodeUsageStatus1': 'Đã sử dụng',
  'campaignGiftManage.qrCodeUsageStatus2': 'Chưa sử dụng',
  'campaignGiftManage.qrCodeUsageStatus3': 'Hết hạn',
  'campaignGiftManage.notSpinYet': 'Không quay',
  'campaignGiftManage.spinFail': 'Không trúng thưởng',
  'campaignGiftManage.PGGivingGiftHistory': 'Lịch sử phát quà của PG',
  'campaignGiftManage.customerPhone': 'Số điện thoại KH',
  'campaignGiftManage.customerName': 'Tên khách hàng',
  'campaignGiftManage.customerImage': 'Hình ảnh',
  'campaignGiftManage.givingGiftTime': 'Thời gian phát thưởng',
};
