export default {
  'campaigns.name': 'Campaign',
  'campaignOperation.name': 'Campaign name',
  'campaignOperation.nameTitle': 'Operate campaign',
  'campaignOperation.recyclingMachines': 'Recycling machine name',
  'campaignOperation.location': 'Location',
  'campaignOperation.takePlaceFrom': 'Start day',
  'campaignOperation.takePlaceTo': 'End date',
  'campaignOperation.rule': 'Winning rules',
  'campaignOperation.numberOfHits': 'Number of access',
  'campaignOperation.validAmount': 'Heineken cans',
  'campaignOperation.invalidAmount': 'Other cans',
  'campaignOperation.action': 'Action',
  'campaigns.edit': 'Campaign editing',
  'campaignOperation.numberOfDistributed': 'The number of gifts has been distributed',

  'campaignOperation.time': 'Time',
  'campaignOperation.locations': 'Place of implementation',
  'campaignOperation.machine': 'Recycling machine',
  'campaignOperation.ruleGift': 'Winning rules',
  'campaignOperation.placeholder': 'Please choose a recycling machine',
  'campaignOperation.placeholder.name': 'Please enter the campaign name',
  'campaignOperation.info.name': 'Campaign name',
  'campaignOperation.typeGift': 'Please enter the number of initial gifts',
  'campaignOperation.numberOfWon': 'The number of QR codes has won',
  'campaignOperation.content.delete': 'Do you want to delete this campaign?',
  'campaignOperation.placeholder.locations': 'Please select the implementation location',

  'campaign-operation.create': 'Add a new campaign',
  'campaigns.add': 'Create a new campaign',
  'campaigns.info': 'Campaign information',
  'campaignOperation.placeholder.ruleGift': 'Please select the winning rule',
  'campaignGifts.typeGift': 'Gift type',
  'campaignGifts.quantityGift': 'Number of gifts',
  'campaignGifts.title': 'Please enter the number of initial gifts!',
  'campaignGifts.placeholder.limit': 'Please enter the number',
  'campaignGifts.limit': 'Please enter the number of gifts',

  'gift.info.name': 'Campaign name:',
  'gift.info.location': 'Location:',
  'gift.info.machine': 'Recycling machine:',
  'gift.info.rule': 'Winning rules:',
  'gift.info.startDay': 'Since:',
  'gift.info.endDay': 'To date:',
  'gift.info.status': 'Campaign status:',

  'campaignOperation.status': 'Campaign',
  'campaign.status.param':
    '{status,select, 1 {Not started} 2 {Happenning} 3 {Paused} 4 {Finished} other {Unknown}}',

  'campaignGifts.generalInfo': 'General information',
  'campaignGifts.history': 'History',

  'campaignOperation.filter.status': 'Campaign status',
  'campaignOperation.filter.status.notStarted': 'Not started',
  'campaignOperation.filter.status.happenning': 'Happenning',
  'campaignOperation.filter.status.paused': 'Paused',
  'campaignOperation.filter.status.finished': 'Finished',
  'campaignOperation.required': 'Please enter the end date',
};
