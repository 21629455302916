import React from 'react';
import { Gift } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerGift: IRouter = {
  path: '/gifts',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gift.names', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Gift />,
  },
  permissionCode: [
    PermissionEnum.GiftsView,
  ],
};

export const routerFormGiftInfo: IRouter = {
  path: '/gifts/:type/:id',
  loader: React.lazy(() => import('../Gift/component/MainModal/FormGiftInfo')),
  exact: true,
  name: 'gift.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.GiftsViewDetail,
};
