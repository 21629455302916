export default {
  //tab1
  'customer.title': 'Quản lý khách hàng',
  'customer.card.percentageHaveInfor': 'Tỷ lệ khách hàng cung cấp thông tin',
  'customer.card.numberOfInforObtained': 'Tổng số khách hàng thu được thông tin',
  'customer.card.customer': 'Khách hàng',

  'customer.phoneNumber': 'Số điện thoại',
  'customer.name': 'Tên khách hàng',
  'customer.numberOfWon': 'Số quà đã trúng',
  'customer.numberOfLocations': 'Số địa điểm hoạt động',
  'customer.obtainedAmount': 'Số lon đã đặt',
  'customer.validAmount': 'Số lon Heineken',
  'customer.invalidAmount': 'Số lon khác',

  //tab2
  'customerInfo.name': 'Thông tin chi tiết khách hàng',
  'customer.attach.title': 'Thông tin tương tác của khách hàng',
  'customerInfo.approachTimeFilter': 'Thời gian tương tác',
  'customerInfo.qrCodeUsageTimeFilter': 'Thời gian phát thưởng',
  'customerInfo.approachTime': 'Thời gian tiếp cận',
  'customerInfo.campaign.name': 'Tên chiến dịch',
  'customerInfo.recyclingMachine.name': 'Máy tái chế',
  'customerInfo.campaign.location': 'Địa điểm',
  'customerInfo.totalObtained': 'Số lon đã đặt',
  'customerInfo.totalValid': 'Số lon Heineken',
  'customerInfo.totalInValid': 'Số lon khác',
  'customerInfo.winningTime': 'Thời gian trúng thưởng',
  'customerInfo.qrCode': 'Mã QR code',
  'customerInfo.gift.name': 'Quà tặng',
  'customerInfo.qrCodeUsageTime': 'Thời gian phát thưởng',
  'customerInfo.prizeGiver.name': 'Nhân viên phát thưởng',
  'customerInfo.image': 'Hình ảnh',

  //share
  'customer.startDay': 'Từ ngày',
  'customer.endDay': 'Đến ngày',
  'customer.action': 'Hành động',
  'customer.update': 'Cập nhật khách hàng',
};
