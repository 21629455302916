export const logo = require('./logo.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const iconTotal = require('./iconTotal.png');
export const iconDevice = require('./iconDevice.png');
export const iconHeineken = require('./iconHeineken.png');
export const iconOther = require('./iconOther.png');
export const iconGift = require('./iconGift.png');
export const iconCustomer = require('./iconCustomer.png');
export const iconBack = require('./iconBack.png');
export const iconQRCode = require('./iconQRCode.png');
export const iconGift1 = require('./iconGift1.png');
export const iconGift2 = require('./iconGift2.png');

export { default as ArrowLeftImg } from './arrow-left.png';
export { default as customer } from './customer.png';
export { default as customerPercent } from './customerPrecent.png';

import L from 'leaflet';

export const silverOnline = require('./siver-online.png');
export const silverOffline = require('./siver-offline.png');
export const deliveryOnline = require('./delivery-online.png');
export const deliveryOffline = require('./delivery-offline.png');
export const recycleOnline = require('./recyce-online.png');
export const recycleOffline = require('./recyce-offline.png');
export { default as qrCodeScan } from './qr-code-scan.png';
