import React from 'react';

import { IRouter } from '@routers/interface';

export const routerGiftDetailScreen: IRouter = {
  path: '/gilt-detail-screen',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gilt-detail.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerFormGiftDetailInfo: IRouter = {
  path: '/gilt-detail-screen/getDetailByQrCode',
  loader: React.lazy(() => import('../GiftDetailScreen/page/FormGiftInfo')),
  exact: true,
  name: 'gilt-detail.info', //translate here for breadcrumb and sidebar
  // masterLayout: true,
};

export const routerFormGiftAdditionalInfo: IRouter = {
  path: '/gilt-detail-screen/additionalInfo',
  loader: React.lazy(() => import('../GiftDetailScreen/page/AdditionalInfo')),
  exact: true,
  name: 'gilt-detail.additionalInfo', //translate here for breadcrumb and sidebar
  // masterLayout: true,
};