export default {
  'recycle.title': 'Recycling machines management',
  'recycle.listOfRecyclingMachines': 'Recycling machines management',
  'recycle.code': 'Recycling machines code',
  'recycle.location': 'Location',
  'recycle.areas': 'Areas',
  'recycle.createdAt': 'Day at',
  'recycle.numberOfHits': 'Number of hits',
  'recycle.validAmount': 'Heineken bottle',
  'recycle.invalidAmount': 'Other bottle',
  'recycle.activeStatus': 'Status',
  'recycle.online': 'Online',
  'recycle.offline': 'Offline',
  'recycle.containerStatus': 'Container status',
  'recycle.notFull': 'Not full',
  'recycle.soonFull': 'Soon full',
  'recycle.action': 'Action',
  'recycle.create': 'Add new Recycling machines',
  'recycle.totalRecyclingMachine': 'Tolal of recycling machines',
  'recycle.totalObtained': 'Total obtained',
  'recycle.totalValid': 'Total Heineken bottle',
  'recycle.totalInValid': 'Other bottle',
  'recycle.device': 'Device',
  'recycle.can': 'Bottle',
  'recycle.name': 'Recycling machines name',
  'recycle.madeIn': 'Made in',
  'recycle.description': 'Description',
  'recycle.content.delete': 'Do you want to delete this Recycling machines?',
  'recycle.update': 'Update information Recycling machines',
  'recycle.information': 'Recycling machines information',
  'recycle.detail-page': 'Recycling machines information',
  'recycle.detail-code': 'Recycling machines code',
  'recycle.detail-name': 'Device name',
  'recycle.detail-description': 'Description',
  'recycle.detail-address': 'Location',
  'recycle.detail-validAmount': 'Heineken bottle',
  'recycle.detail-invalidAmount': 'Other bottle',
  'recycleDetail.approachTime': 'Approach time',
  'recycleDetail.totalObtained': 'Total obtained',
  'recycleDetail.totalValid': 'Heineken bottle',
  'recycleDetail.totalInValid': 'Other bottle',
  'recycleDetail.numberOfWon': 'Points winner',
  'recycleDetail.gift-name': 'Gift name',
  'recycleDetail.winningTime': 'Winning time',
  'recycleDetail.qrCode': 'QR code',
  'recycleDetail.qrCodeUsageStatus': 'QR code status',
  'recycleDetail.qrCodeUsageTime': 'QR code time',
  'recycleDetail.prizeGiver-name': 'Prize giver',
  'recycleDetail.qrCodeUsageStatus.id':
    '{status, select, 1 {Used} 2 {Not-use} 3 {Expired} other {Undefined}}',
  'recycleDetail.used': 'Used',
  'recycleDetail.not-used': 'Not used',
  'recycleDetail.expired': 'Expired',
  'recycleDetail.createdAt': 'Time of full container',
  'recycleDetail.title': 'History container',
  'recycleDetail.history': 'History container',
  'recycleDetail.heading-history': 'History of recycling machines',
  'recycle.activeStatus.id': '{status, select, 1 {Online} 2 {Offline} other {Undefined}}',
  'recycle.containerStatus.id': '{status, select, 1 {Not full} 2 {Soon full} other {Undefined}}',
  'recycleDetail.notSpin': 'No spin',
  'recycleDetail.notPrize': 'No prize',

  'modalRecycleDetail.createdAt': 'Time of full container',
  'modalRecycleDetail.location': 'Location',
  'modalRecycleDetail.totalObtained': 'Total obtained',
  'modalRecycleDetail.totalValid': 'Heineken bottle',
  'modalRecycleDetail.totalInValid': 'Other bottle',

  'recycle.thousand-can': 'Thousand bottles',
  'recycle.million-can': 'Million bottles',
  'recycle.thousand-device': 'Thousand devices',
  'recycle.million-device': 'Million devices',

  'recycle.startDay': 'Start day',
  'recycle.endDay': 'End day',
  'recycleDetail.location': 'Location',
  'recycle.waterContainerStatus': 'Water container status',
  'recycleDetail.history-water': 'History of water container',

  'recycleDetail.name': "Campaign's name",
  'recycleDetail.nameGift': "Gift's name",
  'recycleDetail.startDay': 'Start day',
  'recycleDetail.endDay': 'End day',
  'recycleDetail.qrCodeUsageStatus-label': 'QR code usage status',
  'recycleDetail.noSpin': 'No spin',
  'recycleDetail.noPrize': 'No prize',
  'recycleDetail.waterHistory': 'History of water container',
};
