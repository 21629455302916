import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerUsers: IRouter = {
  path: '/users',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'users.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.UsersView,
};

export const routerUserInfo: IRouter = {
  path: '/users/info/:tab/:id',
  loader: React.lazy(() => import('./component/MainModal/UserInfo')),
  exact: true,
  name: 'users.info', //translate here for breadcrumb and sidebar
  // permissionCode: PermissionEnum.CampaignsViewDetail,
};
