export default {
  'recycle.title': 'Quản lý máy tái chế',
  'recycle.listOfRecyclingMachines': 'Danh sách máy tái chế',
  'recycle.code': 'Mã máy tái chế',
  'recycle.location': 'Địa điểm hoạt động',
  'recycle.areas': 'Khu vực',
  'recycle.createdAt': 'Ngày thêm',
  'recycle.numberOfHits': 'Số lần tiếp cận',
  'recycle.validAmount': 'Số lon Heineken',
  'recycle.invalidAmount': 'Số lon khác',
  'recycle.activeStatus': 'Trạng thái hiện tại',
  'recycle.online': 'Online',
  'recycle.offline': 'Offline',
  'recycle.containerStatus': 'Trạng thái thùng chứa',
  'recycle.notFull': 'Chưa đầy',
  'recycle.soonFull': 'Sắp đầy',
  'recycle.action': 'Hành động',
  'recycle.create': 'Thêm máy tái chế mới',
  'recycle.totalRecyclingMachine': 'Tổng số máy tái chế',
  'recycle.totalObtained': 'Tổng số lon thu được',
  'recycle.totalValid': 'Tổng số lon Heineken',
  'recycle.totalInValid': 'Tổng số lon khác',
  'recycle.device': 'Máy',
  'recycle.can': 'Lon',
  'recycle.name': 'Tên máy tái chế',
  'recycle.madeIn': 'Nơi sản xuất',
  'recycle.description': 'Mô tả',
  'recycle.content.delete': 'Bạn có xác nhận xóa máy tái chế này?',
  'recycle.update': 'Cập nhật thông tin máy tái chế',
  'recycle.information': 'Thông tin máy tái chế',
  'recycle.detail-page': 'Thông tin máy tái chế',
  'recycle.detail-code': 'Mã máy tái chế',
  'recycle.detail-name': 'Tên máy',
  'recycle.detail-description': 'Mô tả',
  'recycle.detail-address': 'Vị trí hiện tại',
  'recycle.detail-validAmount': 'Tổng lon Heineken',
  'recycle.detail-invalidAmount': 'Tổng lon khác',
  'recycleDetail.approachTime': 'Thời gian tiếp cận',
  'recycleDetail.totalObtained': 'Tổng số lượng lon được thu hồi',
  'recycleDetail.totalValid': 'Số lon Heineken',
  'recycleDetail.totalInValid': 'Số lon khác',
  'recycleDetail.numberOfWon': 'Tổng điểm quy đổi',
  'recycleDetail.gift-name': 'Quà tặng nhận được',
  'recycleDetail.winningTime': 'Thời gian trúng thưởng',
  'recycleDetail.qrCode': 'Mã QR code',
  'recycleDetail.qrCodeUsageStatus': 'Trạng thái sử dụng QR code',
  'recycleDetail.qrCodeUsageTime': 'Thời gian sử dụng QR code',
  'recycleDetail.prizeGiver-name': 'Nhân viên phát thưởng',
  'recycleDetail.qrCodeUsageStatus.id':
    '{status, select, 1 {Đã sử dụng} 2 {Chưa sử dụng} 3 {Hết hạn} other {Không xác định}}',
  'recycleDetail.used': 'Đã sử dụng',
  'recycleDetail.not-used': 'Chưa sử dụng',
  'recycleDetail.expired': 'Hết hạn',
  'recycleDetail.createdAt': 'Thời gian đầy thùng',
  'recycleDetail.title': 'Lịch sử đầy thùng chứa',
  'recycleDetail.history': 'Lịch sử thùng chứa',
  'recycleDetail.heading-history': 'Lịch sử hoạt động của máy tái chế',
  'recycle.activeStatus.id': '{status, select, 1 {Online} 2 {Offline} other {Không xác định}}',
  'recycle.containerStatus.id': '{status, select, 1 {Chưa đầy} 2 {Sắp đầy} other {Không xác định}}',
  'recycleDetail.notSpin': 'Không quay',
  'recycleDetail.notPrize': 'Không trúng thưởng',

  'modalRecycleDetail.createdAt': 'Thời gian đầy thùng',
  'modalRecycleDetail.location': 'Địa điểm',
  'modalRecycleDetail.totalObtained': 'Tổng số lượng lon được thu hồi',
  'modalRecycleDetail.totalValid': 'Số lon Heineken',
  'modalRecycleDetail.totalInValid': 'Số lon khác',

  'recycle.thousand-can': 'Nghìn lon',
  'recycle.million-can': 'Triệu lon',
  'recycle.thousand-device': 'Nghìn máy',
  'recycle.million-device': 'Triệu máy',

  'recycle.startDay': 'Ngày bắt đầu',
  'recycle.endDay': 'Ngày kết thúc',
  'recycleDetail.location': 'Địa điểm',
  'recycle.waterContainerStatus': 'Trạng thái thùng nước',
  'recycleDetail.history-water': 'Lịch sử thùng nước',

  'recycleDetail.name': 'Tên chiến dịch',
  'recycleDetail.nameGift': 'Quà tặng',
  'recycleDetail.startDay': 'Từ ngày',
  'recycleDetail.endDay': 'Đến ngày',
  'recycleDetail.qrCodeUsageStatus-label': 'Trạng thái sử dụng',
  'recycleDetail.noSpin': 'Không quay',
  'recycleDetail.noPrize': 'Không trúng thưởng',
  'recycleDetail.waterHistory': 'Lịch sử đầy thùng nước',
};
