import React from 'react';

import { IRouter } from '@routers/interface';

export const routerBeginScreenCampaignsDetail: IRouter = {
  path: '/begin-screen/campaigns-detail',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'beginScreen-campaignsDetail.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
