import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerCustomerInfo: IRouter = {
  path: '/customer/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customerInfo.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.CustomerViewDetail,
};
