import React from 'react';

import { IRouter } from '@routers/interface';

export const routerGiftStatistics: IRouter = {
  path: '/gift-statistics',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'giftStatistics.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};