export default {
  'historyScreen.title': 'Danh sách quà tặng đã phát',
  'historyScreen.gift': 'Quà tặng:',
  'historyScreen.qrCode': 'QR code:',
  'historyScreen.time': 'Thời gian:',
  'historyScreen.customer': 'Khách hàng:',
  'historyScreen.view-detail': 'Xem chi tiết',
  'historyScreen.recall': 'Thu hồi',

  'webapp.prev': 'Trang trước',
  'webapp.next': 'Trang sau',

  'info-gift.header': 'Thông tin chi tiết',
  'info-gift.title-1': 'Thông tin quà tặng',
  'info-gift.title-2': 'Thông tin phát thưởng',
  'info-gift.campaignName': 'Tên chiến dịch',
  'info-gift.qrCode': 'Mã QR code quà tặng',
  'info-gift.location': 'Địa điểm',
  'info-gift.recyclingMachine': 'Máy tái chế xuất QR code',
  'info-gift.giftName': 'Tên quà tặng',
  'info-gift.qrCodeUsageTime': 'Thời gian phát thưởng',
  'info-gift.phoneNumber': 'Số điện thoại khách hàng',
  'info-gift.customerName': 'Tên khách hàng',
  'info-gift.prizeGiver': 'Tên người phát quà:',
  'info-gift.image': 'Hình ảnh:',
  'select-gift': 'Quà tặng',
  'modal-accept.placeholder': 'Vui lòng nhập lý do thu hồi',
  'modal-accept.title': 'Bạn có muốn thu hồi lần trao quà này không?',
  'modal-accept.reason': 'Lý do thu hồi',
  'modal-accept.validator-reason': 'Vui lòng nhập lí do thu hồi',
};
