export default {
  'report.name': 'Báo cáo',
  'robotSilverbot.name': 'Robot Silverbot',
  'deliveryRobot.name': 'Robot giao hàng',
  'recyclingMachine.name': 'Máy tái chế',
  'giftStatistics.name': 'Thống kê quà tặng',

  'filter.title': 'Vui lòng chọn thông tin cần báo cáo!',
  'filter.date': 'Thời gian báo cáo',
  'filter.location': 'Địa điểm',
};
