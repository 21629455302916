import store from '@core/store/redux';
import UserEntity from '@modules/user/entity';

import profileStore, { setToken } from './profileStore';
import authenticationRepository, { ILoginDTO } from './repository';

const authenticationPresenter = { ...authenticationRepository };

authenticationPresenter.login = (payload: ILoginDTO, remember = false) => {
  return authenticationRepository.login(payload).then((res: any) => {
    store.dispatch(setToken({ token: res.accessToken, refreshToken: res.refreshToken, remember }));
    return res;
  });
};

authenticationPresenter.getProfile = () => {
  return authenticationRepository.getProfile().then((user: UserEntity) => {
    store.dispatch(
      profileStore.actions.fetchProfile({ user, listPermissionCode: user.permissions }),
    );
    return Promise.resolve(user);
  });
};

export default authenticationPresenter;
