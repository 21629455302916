export default {
  'beginScreen.name': 'Enter',
  'beginScreen.title': 'Enter',
  'beginScreen.provinces': 'Provinces',
  'beginScreen.districts': 'Districts',
  'beginScreen.location': 'Location',
  'beginScreen.createdAt': 'Time',
  'beginScreen.campaigns': 'Campaigns',
  'beginScreen.startDay': 'Start day',
  'beginScreen.endDay': 'End day',
  'beginScreen.campaign': 'End day',
  'beginScreen.locations': 'Location',
  'beginScreen.time': 'Time',
  'beginScreen.change': 'Change',
  'beginScreen.content.change': 'Do you want to change this campaign?',
  'beginScreen.activities': 'Statistic activities',
  'beginScreen.nameGift': 'Gift name',
  'beginScreen.numberOfDistributed': 'Total of distributed gifts',
  'beginScreen.numberOfNotYetDistributed': 'Total of not distributed gifts',
  'beginScreen.inventory': 'Inventory',
  'beginScreen.gift': 'Gift',
  'beginScreen.distributed': 'Total of distributed gifts',
  'beginScreen.customer': 'Customer',
  'beginScreen.customerInfo': 'Number of customers to collect information',
  'beginScreen.campaignSelect': 'Select campaign',
};
