import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Routes, useLocation } from 'react-router-dom';

import config from '@config/index';
import DefaultLayout, { DefaultLayoutWebApp } from '@layout/index';
import authenticationPresenter from '@modules/authentication/presenter';
import { TokenSelector } from '@modules/authentication/profileStore';
import { webAppPage, privatePage } from '@routers/mainRouter';
import { useSingleAsync } from '@shared/hook/useAsync';

import useRouter from './useRouter';

enum TYPE {
  SYSTEM = 1,
  MARKETERS = 2,
}

const PrivatePage: React.FC = () => {
  const token = useSelector(TokenSelector);
  const location = useLocation();
  const getProfile = useSingleAsync(authenticationPresenter.getProfile);
  const [privateType, setPrivateType] = useState<number>();

  useEffect(() => {
    if (token) {
      getProfile.execute().then(res => {
        setPrivateType(res.type);
      });
    } else {
      window.location.href = config.LOGIN_PAGE;
    }
  }, [token]);

  const { views, routes } = useRouter({
    routers: privateType === TYPE.MARKETERS ? webAppPage : privatePage,
    privateRoute: true,
  });

  const showMasterLayout = React.useMemo(() => {
    const r = routes.find(it => it.path && matchPath(it.path, location.pathname));
    return r?.masterLayout !== false;
  }, [location.pathname, routes]);

  return (
    <>
      {privateType === TYPE.MARKETERS && (
        <DefaultLayoutWebApp
          hideSideBar={!showMasterLayout}
          hideHeader={!showMasterLayout}
          loading={getProfile.status !== 'ready'}
        >
          <Routes>{views}</Routes>
        </DefaultLayoutWebApp>
      )}
      {privateType === TYPE.SYSTEM && (
        <DefaultLayout
          hideSideBar={!showMasterLayout}
          hideHeader={!showMasterLayout}
          loading={getProfile.status !== 'ready'}
        >
          <Routes>{views}</Routes>
        </DefaultLayout>
      )}
    </>
  );
};
export default React.memo(PrivatePage);
