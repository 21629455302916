export default {
  'device.name': 'Equipment management',
  'device.deviceName': 'Device name',
  'device.deviceCode': 'Device code',
  'device.deviceCapacity': 'Power equipment',
  'device.deviceLong': 'Longitude',
  'device.deviceLat': 'Latitude',
  'device.isActive': 'Active status',
  'device.devicePassword': 'Password',
  'device.create': 'Add device',
  'device.update': 'Device update',
  'device.information': 'Device Information',
  'device.delete.title': 'Delete device',
  'device.content.delete':
    'You are sure to delete the device.The deleted device will not be recovered ',
  'device.deviceType': 'Equipment type',
  'device.delete': 'Delete device',
  'device.action': 'Operation',
  'device.location': 'Location',
};
