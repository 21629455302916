import RoleEntity from '@modules/roles/entity';

class PermissionsEntity {
  resource = '';

  permissions: RoleEntity[] = [];

  constructor(permissions: Partial<PermissionsEntity>) {
    if (!permissions) {
      return;
    }
    Object.assign(this, permissions);
  }

  static createListPermissions(listPermissions: Array<Partial<PermissionsEntity>>) {
    if (!Array.isArray(listPermissions)) {
      return [];
    }
    return listPermissions.map((permissions: Partial<PermissionsEntity>) => {
      return new PermissionsEntity(permissions);
    });
  }
}

export enum PermissionEnum {
  UsersView = 'UsersView',
  UsersViewDetail = 'UsersViewDetail',
  UsersCreate = 'UsersCreate',
  UsersUpdate = 'UsersUpdate',
  UsersDelete = 'UsersDelete',
  AreasView = 'AreasView',
  AreasViewDetail = 'AreasViewDetail',
  AreasCreate = 'AreasCreate',
  AreasUpdate = 'AreasUpdate',
  AreasDelete = 'AreasDelete',
  LocationsView = 'LocationsView',
  LocationsViewDetail = 'LocationsViewDetail',
  LocationsCreate = 'LocationsCreate',
  LocationsUpdate = 'LocationsUpdate',
  LocationsDelete = 'LocationsDelete',
  GiftsView = 'GiftsView',
  GiftsViewDetail = 'GiftsViewDetail',
  GiftsCreate = 'GiftsCreate',
  GiftsUpdate = 'GiftsUpdate',
  GiftsDelete = 'GiftsDelete',
  RecyclingMachinesView = 'RecyclingMachinesView',
  RecyclingMachinesViewDetail = 'RecyclingMachinesViewDetail',
  RecyclingMachinesCreate = 'RecyclingMachinesCreate',
  RecyclingMachinesUpdate = 'RecyclingMachinesUpdate',
  RecyclingMachinesDelete = 'RecyclingMachinesDelete',
  RobotsView = 'RobotsView',
  RobotsViewDetail = 'RobotsViewDetail',
  RobotsCreate = 'RobotsCreate',
  RobotsUpdate = 'RobotsUpdate',
  RobotsDelete = 'RobotsDelete',
  RolesView = 'RolesView',
  RolesViewDetail = 'RolesViewDetail',
  RolesCreate = 'RolesCreate',
  RolesUpdate = 'RolesUpdate',
  RolesDelete = 'RolesDelete',
  CampaignsCreate = 'CampaignsCreate',
  CampaignsDelete = 'CampaignsDelete',
  CampaignsUpdate = 'CampaignsUpdate',
  CampaignsView = 'CampaignsView',
  CampaignsViewDetail = 'CampaignsViewDetail',
  RulesCreate = 'RulesCreate',
  RulesDelete = 'RulesDelete',
  RulesUpdate = 'RulesUpdate',
  RulesView = 'RulesView',
  RulesViewDetail = 'RulesViewDetail',
  CustomerUpdate = 'CustomerUpdate',
  CustomerView = 'CustomerView',
  CustomerViewDetail = 'CustomerViewDetail',
}

export default PermissionsEntity;
