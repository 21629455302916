export default {
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AddBeat.Success': 'Adding success',
  'Server.DeleteBeat.Success': 'Deleting success',
  'Server.UpdateBeat.Success': 'Updating success',
  'Mes.Users.Add.Successfully': 'Add successful account',
  'Mes.Users.DeleteUsers.Successfully': 'Successfully delete',
  'Mes.Users.UpdateSuccessfully': 'successfulAccountInformationUpdates',
  'Mes.Researchs.Update.Successfully': 'Updating successful research',
  'Mes.Researchs.Add.Successfully': 'Add a successful study',
  'Mes.Researchs.Delete.Successfully': 'Delete successful research',
  'server.data.success': 'Successful',
  Successful: 'Successful',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'The recovery link has been sent to your email',
  'Mes.User.ResetPasswordSuccessfully': 'Successful password reset',
  'Mes.Profile.GetDetailSuccessfully': 'Get successful account information',
  'Mes.Profile.OldPassword.Wrong': 'The old password is not correct',
  'Mes.Profile.ChangePasswordSuccessfully': 'Successful password update',
  'Mes.Role.UpdateSuccessfully': 'Update the role of success',
  'Mes.Role.CreateSuccessfully': 'Add a successful role ',
  'Mes.Role.DeleteSuccessfully': 'Delete the role of success',

  'Mes.RefreshToken.Token.NotFound': 'Not found token',
  'Mes.RefreshToken.Token.InValid': 'Refresh Token is invalid',
  'Mes.User.RefreshToken.Expired': 'Refresh token has expired',
  'Mes.User.RefreshToken.Required': 'Refresh token does not leave empty',

  'Mes.Role.Code.AlreadyExist': 'Role code already exists',
  'Mes.Role.NotFound': 'Not found the role',
  'Mes.Role.Assigned': 'Unable to delete the role that has been decentralized',
  'Mes.Role.Create.Successfully': 'Create a successful role',
  'Mes.Role.Update.Successfully': 'Update the role of success',
  'Mes.Role.Update.Failed': 'Update the role of failure',
  'Mes.Role.Search.Successfully': 'See a list of successes',
  'Mes.Role.GetDetail.Successfully': 'See successful details',
  'Mes.Role.Delete.Successfully': 'Delete the role of success',
  'Mes.Role.Delete.Failed': 'Delete the role of failure',
  'Mes.Role.Code.Required': 'Role code is required',
  'Mes.Role.Name.Required': 'Name role is required',
  'Mes.Role.Id.InValid': 'ID of an invalid role',

  'Mes.Permission.Code.InValid': 'Invalid code',
  'Mes.User.Create.Successfully': 'Create successful users',
  'Mes.User.Update.Successfully': 'Update user success',
  'Mes.User.Update.Failed': 'Update user failure',
  'Mes.User.Search.Successfully': 'See a list of successful users',
  'Mes.User.GetDetail.Successfully': 'See successful details',
  'Mes.User.Delete.Successfully': 'Delete successful users',
  'Mes.User.Delete.Failed': 'Delete user failure',
  'Mes.User.NotFound': 'Not found users',
  'Mes.User.NewPassword.Match.OldPassword':
    'The new password is not coincided with the old password',
  'Mes.User.OldPassword.InValid': 'Old password is not invalid',
  'Mes.User.Username.InValid': 'Invalid username code',
  'Mes.User.Password.InValid': 'Invalid password',
  'Mes.User.Email.AlreadyExist': 'Email already exists',
  'Mes.User.Username.AlreadyExist': 'Username already exists',
  'Mes.User.Type.Required': 'Type of user is required',
  'Mes.User.Type.InValid': 'Type of invalid users',
  'Mes.User.RoleId.Required': 'Role id is required',
  'Mes.User.Email.Required': "User's email is required",
  'Mes.User.Email.InValid': 'User email is invalid',
  'Mes.User.Username.Required': 'Username is required',
  'Mes.User.Dob.Required': 'Date of birth is required',
  'Mes.User.Name.Required': 'The username is required',
  'Mes.User.Gender.Required': 'Gender is required',
  'Mes.User.Gender.InValid': 'Not quick gender',
  'Mes.User.PhoneNumber.InValid': 'Invalid phone number',
  'Mes.User.Avatar.InValid': 'Invalid photo',
  'Mes.User.OldPassword.Required': 'Old password is required',
  'Mes.User.Password.Required': 'Password is required',

  'Mes.Gift.Create.Successfully': 'Create a successful gift',
  'Mes.Gift.Update.Successfully': 'Successful gift update',
  'Mes.Gift.Update.Failed': 'Update failure',
  'Mes.Gift.Search.Successfully': 'See a list of successful gifts',
  'Mes.Gift.GetDetail.Successfully': 'See successful details',
  'Mes.Gift.Delete.Successfully': 'Successful gift delete',
  'Mes.Gift.Delete.Failed': 'Delete failure',
  'Mes.Gift.NotFound': "Can't find gifts",
  'Mes.Gift.Name.Required': 'The name of the gift is required',
  'Mes.Gift.Unit.Required': 'The unit of the gift is required',
  'Mes.Gift.Illustration.Required': 'Photos of gifts are required',
  'Mes.Gift.Illustration.InValid': 'Photo of invalid gifts',
  'Mes.Gift.Used': 'Gift has been used',

  'Mes.Location.NotFound': "Can't find this location",
  'Mes.Location.Name.Required': 'Location name is required',
  'Mes.Location.Name.OverLength': 'Name exceeds the allowed character',
  'Mes.Location.Address.Required': 'Address is required',
  'Mes.Location.Address.OverLength': 'The address exceeds the allowed character',
  'Mes.Location.ProvinceCode.Required': 'Model of Province is required',
  'Mes.Location.DistrictCode.Required': 'District code is required',
  'Mes.Location.CommuneCode.Required': 'District code is required',
  'Mes.Addresses.NotFound': "Can't found the address",
  'Mes.Location.Create.Successfully': 'Create a successful location',
  'Mes.Location.Update.Successfully': 'Edit the successful location',
  'Mes.Location.Delete.Successfully': 'Delete the successful location',
  'Mes.Location.GetDetail.Successfully': 'Get the details of the successful location',
  'Mes.Location.Search.Successfully': 'Search for a successful location',

  'Mes.Area.Create.Successfully': 'Create a successful area',
  'Mes.Area.Update.Successfully': 'Update the successful area ',
  'Mes.Area.Delete.Successfully': 'Delete the successful area',
  'Mes.Area.NotFound': 'Not found this area',
  'Mes.Area.Name.Required': 'Name is required',
  'Mes.Area.Name.OverLength': 'Name exceeds the allowed character',
  'Mes.Area.Decription.OverLength': 'Description exceeds the allowed character',
  'Mes.Province.Code.Required': 'Model of Province is required',
  'Mes.Province.Code.Duplicate': 'Model of overlapping province',
  'Mes.Province.NotFound': 'Not found this province',

  'Mes.Robots.Code.IsNotAllowedToUpdate': 'Robot code is not allowed to update',
  'Mes.Robots.Id.IsRequired': 'Robot id is not empty',
  'Mes.Robots.Id.IdIsNotfound': "ID ID can't find it",
  'Mes.Robots.Code.IsRequired': 'required robot code',
  'Mes.Robots.Code.LengthIsInvalid': 'The length of an invalid robot code',
  'Mes.Robots.Code.IsAvailable': 'Robot code has existed',
  'Mes.Robots.Name.IsRequired': 'Robot name is not empty',
  'Mes.Robots.Name.LengthIsInvalid': 'The length of the robot name is invalid',
  'Mes.Robots.Name.IsAvailable': 'The name of the robot is already available',
  'Mes.Robots.MadeIn.IsRequired': 'The place of production is not empty',
  'Mes.Robots.MadeIn.LengthIsInvalid': 'The length of the production place is invalid',
  'Mes.Robots.Description.IsRequired': 'Invalid robot description',
  'Mes.Robots.Description.LengthIsInvalid': 'The length of the irregular robot description',
  'Mes.Robots.RobotType.IsRequired': 'The type of robot is not empty',
  'Mes.Robots.RobotType.IsInvalid': 'Valid Robot type',
  'Mes.Robots.GetDetail.Successfully': 'Get successful robot details',
  'Mes.Robots.Create.Successfully': 'Successful robot coordinates',
  'Mes.Robots.Update.Successfully': 'Successful robot update',
  'Mes.Robots.Delete.Successfully': 'Delete a successful robot',
  'Mes.Robots.Search.Successfully': 'Search for successful robots',
  'Mes.Robots.RobotType.NotAllowedToUpdate': 'This type of robot is not allowed to update',

  'Mes.HistoryFullContainers.GetDetail.Successfully':
    'Get detailed details of the container history',
  'Mes.HistoryFullContainers.Create.Successfully': 'Create a successful container history',
  'Mes.HistoryFullContainers.Update.Successfully': 'Successful update of the container history',
  'Mes.HistoryFullContainers.Delete.Successfully': 'Delete the history of successful containers',
  'Mes.HistoryFullContainers.RecyclingMachineId.IsRequired':
    'The ID of the recycling machine is not empty',
  'Mes.HistoryFullContainers.RecyclingMachineId.IsAvailable':
    'The ID of the recycling machine is already available',
  'Mes.HistoryFullContainers.RecyclingMachineId.IsNotfound':
    'The ID of the recycling machine cannot be found',
  'Mes.HistoryFullContainers.TotalValid.IsRequired':
    'The total number of heniken cans is not empty',
  'Mes.HistoryFullContainers.Search.Successfully': 'Searching for the history of the container',
  'Mes.HistoryFullContainers.TotalInValid.IsRequired':
    'The total number of other cans is not empty',

  'Mes.ReallHistories.RecyclingMachineId.IsRequired':
    'The ID of the recycling machine is not empty',
  'Mes.ReallHistories.RecyclingMachineId.IsAvailable':
    'The ID of the recycling machine is already available',
  'Mes.ReallHistories.RecyclingMachineId.IsNotfound':
    'The ID of the recycling machine cannot be found',
  'Mes.ReallHistories.GetDetail.Successfully': 'Take success of recycling machine details',
  'Mes.ReallHistories.Create.Successfully': 'Create a successful recycling machine',
  'Mes.ReallHistories.Update.Successfully': 'Successful recycling machine update',
  'Mes.ReallHistories.Delete.Successfully': 'Successful recycling machine',
  'Mes.ReallHistories.TotalValid.IsRequired': 'The total number of heniken cans is not empty',
  'Mes.ReallHistories.Search.Successfully': 'Searching for a successful recycling machine',
  'Mes.ReallHistories.TotalInValid.IsRequired': 'The total number of other cans is not empty',

  'Mes.Devices.GetDetail.Successfully': 'Get successful equipment details',

  'Mes.RecyclingMachines.Create.Successfully': 'Create a successful recycling machine',
  'Mes.RecyclingMachines.Update.Successfully': 'Successful recycling machine update',
  'Mes.RecyclingMachines.Delete.Successfully': 'Successful recycling machine delete',
  'Mes.RecyclingMachines.Id.IsRequired': 'The ID of the recycling machine is not empty',
  'Mes.RecyclingMachines.Id.IdIsNotfound': 'The ID of the recycling machine is not found',
  'Mes.RecyclingMachines.Code.IsRequired': 'The code of the recycling machine is not empty',
  'Mes.RecyclingMachines.Code.LengthIsInvalid': 'The length of invalid recycling machine code',
  'Mes.RecyclingMachines.Code.IsAvailable': 'The code of the machine is already available',
  'Mes.RecyclingMachines.Name.IsRequired': 'The name of the recycling machine is not empty',
  'Mes.RecyclingMachines.Name.LengthIsInvalid':
    'The length of the name of the recycling machine is invalid',
  'Mes.RecyclingMachines.Name.IsAvailable': 'The name of the recycling machine is available',
  'Mes.RecyclingMachines.MadeIn.IsRequired': 'The place of production is not empty',
  'Mes.RecyclingMachines.MadeIn.LengthIsInvalid': 'The length of the production place is invalid',
  'Mes.RecyclingMachines.Description.IsRequired':
    'The description of the recycling machine is not empty',
  'Mes.RecyclingMachines.Description.LengthIsInvalid':
    'The length of invalid recycling machine description',
  'Mes.RecyclingMachines.Search.Successfully': 'Successful search for recycling machine',
  'Mes.recyclingmachines.createrecallhistory.successfully':
    'Create a successful recycling machine history',
  'Mes.recyclingmachines.createhistoryfullcontainer.successfully':
    'Create a history full of successful containers',
  'Mes.recyclingmachines.searchrecallhistory.successfully':
    'Searching for successful recycling machine operations',
  'Mes.recycling Machines.Search HistoryFull Container.successfully':
    'Search for a successful container history',
  'Mes.RecyclingMachines.Code.IsNotAllowedToUpdate': 'Do not update recycling machine code',
  'Mes.RecyclingMachines.IsNotAllowedToDelete': 'Not allowed to remove recycling machines',
  'Mes.RecyclingMachines.Code.Required':
    'The recycling machine code is not allowed to leave the blank',
  'Mes.RecyclingMachines.Name.Required':
    'The name of the recycling machine is not allowed to leave the blank',
  'Mes.RecyclingMachines.IsUsed': 'Can not delete the used recycling machine',
  'Mes.RecyclingMachines.Code.AlreadyExist': 'Recycling machine code exists',

  'Mes.Rule.Create.Successfully': 'Create success rules',
  'Mes.Rule.Update.Successfully': 'Update the rule of success',
  'Mes.Rule.Update.Failed': 'Update failure rules',
  'Mes.Rule.Search.Successfully': 'Search for success rules',
  'Mes.Rule.GetDetail.Successfully': 'Get detail successful information',
  'Mes.Rule.Delete.Successfully': 'Delete the rule of success',
  'Mes.Rule.Delete.Failed': 'Delete the rule of failure',
  'Mes.Rule.NotFound': 'No rules found',
  'Mes.Rule.IsUsing': 'Rules are being used',
  'Mes.Rule.Name.Required': 'The name of the rule is mandatory',
  'Mes.Rule.RuleSettings.InValid': 'The scope of accumulating points is invalid',
  'Mes.Rule.RuleSettings.Required': 'The scope of the accumulated point is mandatory',

  'Mes.RuleSetting.ScoreFrom.Required': 'The starting point is mandatory',
  'Mes.RuleSetting.ScoreFrom.InValid': 'The starting point is invalid',
  'Mes.RuleSetting.ScoreTo.Required': 'The end point is mandatory',
  'Mes.RuleSetting.ScoreTo.InValid': 'The ending point is invalid',
  'Mes.RuleSetting.RuleGiftSettings.Required': 'Gift content is required',
  'Mes.RuleSetting.RuleGiftSettings.InValid': 'The content of gifts is invalid',

  'Mes.RuleGiftSetting.GiftId.Duplicate':
    'Gifts within a scope of accumulated points are not overlapping',
  'Mes.RuleGiftSetting.GiftId.Required': 'Gift ID is required',
  'Mes.RuleGiftSetting.GiftId.InValid': 'Invalid gift ID',
  'Mes.RuleGiftSetting.GiftId.MustBeEmpty': 'No gift ID',
  'Mes.RuleGiftSetting.Type.Required': 'Gift setup type is mandatory',
  'Mes.RuleGiftSetting.Type.InValid': 'Type of invalid gift setup',
  'Mes.RuleGiftSetting.Percent.Required': 'Percentage is mandatory',
  'Mes.RuleGiftSetting.Percent.InValid': 'Valid percentage',

  'Mes.Campaign.Update.Successfully': 'Update the campaign successfully',
  'Mes.Campaign.Delete.Successfully': 'Delete a successful campaign',
  'Mes.Campaign.Create.Successfully': 'Create a successful campaign',
  'Mes.Campaign.LocationId.IsUsed': 'The location has been used',
  'Mes.Campaign.RecyclingMachineIds.IsUsed': 'Recycling machine has been used',
  'Mes.Campaign.Update.CampaignIsEnd': 'Unable to update the campaign has ended',
  'Mes.Campaign.Delete.CampaignIsStarted': 'Can not delete the campaign',
  'Mes.Campaign.TakePlaceFrom.InValid': 'The time for the campaign is invalid.',
  'Mes.Campaign.TakePlaceFrom.CampaignIsStarted': 'The campaign has begun.',
  'Mes.Campaign.Limit.InValid': 'Limit is not allow to be less than the total gifts distributed',

  'Mes.Customer.Update.Successfully': 'Successful customer update',
  'Mes.Customer.Name.Required': 'Please enter the customer name',
  'Mes.Province.IsUsing': 'The province has been in another area',

  'Mes.RecallHistory.QrCode.NotFound': 'Can not find qrcode',
  'Mes.RecallHistory.PrizeGiver.Delivered': 'Gifts were rewarded',
  'Mes.RecallHistory.QrCode.Expires': 'Expired QRCode',
  'Mes.RecallHistory.Reason.NotEmpty': 'The reason for recovery is not allowed to leave blanks',
  'Mes.RecallHistory.Reason.OverLength': 'Reason for the prescribed length',
  'Mes.RecallHistory.GetDetailByQrCode.Successfully': 'Get successful information',
  'Mes.RecallHistory.GetDetail.Successfully': 'Get successful information',
  'Mes.RecallHistory.Id.NotFound': 'Can not find ID',
  'Mes.RecallHistory.Award.Successfully': 'Successful reward change',
  'Mes.RecallHistory.Recovery.Successfully': 'Revokes the gifts successfully',
  'Mes.Campaign.PrizeGiverGiftGivingHistoriesSearch.Successfully':
    'Get successful gift information',

  'Mes.User.PrizeGave': 'Can not delete user operating',
  'Mes.Location.IsUsing': 'Can not delete the location being used',
  'Mes.Campaign.Name.Required': 'The campaign name is not allowed to leave',
  'Mes.Robots.Code.AlreadyExist': 'Coincide the robot code',
  'Mes.Area.IsUsing': 'The affiliated provincial city has been selected in another area',
  'Mes.Area.Description.OverLength': 'Description exceeds the allowed character',
  'Mes.RecallHistory.Update.Failed': 'Update information failure',
  'Mes.Customers.Image.InValidType': 'Image improper format',
  'Mes.Campaign.TakePlaceTo.InValid': 'The ending time is invalid',
};
